const stations = [
  {
    id: 1,
    call_sign: "WXYC",
    broadcast_frequency: "89.3",
    audio_url: "https://audio-mp3.ibiblio.org/wxyc.mp3",
    station_url: "https://wxyc.org/",
    college_name: "University of North Carolina at Chapel Hill",
    public_private: "public",
    city: "Chapel Hill",
    state: "NC",
    station_image: "https://radiostationusa.fm/assets/image/radio/180/WXYC.jpg",
    college_image:
      "https://assets-sports.thescore.com/basketball/team/756/logo.png",
    palette: ["#a4c0e4", "#263663", "#5e6687", "#6c7491"],
  },
  {
    id: 2,
    call_sign: "KALX",
    broadcast_frequency: "90.7",
    audio_url: "https://stream.kalx.berkeley.edu:8443/kalx-128.mp3",
    station_url: "https://www.kalx.berkeley.edu/",
    college_name: "University of California, Berkeley",
    public_private: "public",
    city: "Berkeley",
    state: "CA",
    station_image:
      "https://kalx.berkeley.edu/wp-content/uploads/2024/04/2024-kalx-hoodie-297x300.png",
    college_image: "https://wwll.com/images/logos/teams/cal-256.png",
    palette: ["#092f63", "#f2c134", "#fcf9f3", "#4e566c"],
  },
  {
    id: 3,
    call_sign: "KVRX",
    broadcast_frequency: "91.7",
    audio_url: "https://www.kvrx.org/now_playing/stream",
    station_url: "https://kvrx.org/app/",
    college_name: "University of Texas at Austin",
    public_private: "public",
    city: "Austin",
    state: "TX",
    station_image:
      "https://pbs.twimg.com/profile_images/1021906128332980224/tgMrKr7O_400x400.jpg",
    college_image:
      "https://assets-sports.thescore.com/basketball/team/1069/logo.png",
    palette: ["#d46c2c", "#d86c2c", "#d86c2c", "#d86c2c"],
  },
  {
    id: 6,
    call_sign: "WSUM",
    broadcast_frequency: "91.7",
    audio_url: "https://ice23.securenetsystems.net/WSUMFM",
    station_url: "https://wsum.org/",
    college_name: "University of Wisconsin—Madison",
    public_private: "public",
    city: "Madison",
    state: "WI",
    station_image:
      "https://www.radio.net/images/broadcasts/ec/fb/11375/c300.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Wisconsin_Badgers_logo.svg/814px-Wisconsin_Badgers_logo.svg.png",
    palette: ["#a30505", "#f9f7f7", "#0c0707", "#b17c7c"],
  },
  {
    id: 7,
    call_sign: "KCOU",
    broadcast_frequency: "88.1",
    audio_url: "https://ssl.shoutcaststreaming.us:8088/stream",
    station_url: "https://kcou.fm/",
    college_name: "University of Missouri",
    public_private: "public",
    city: "Columbia",
    state: "MO",
    station_image:
      "https://zeno.fm/_next/image/?url=https%3A%2F%2Fimages.zeno.fm%2FaF2s0GnLVx4-MGD1_4wNuVtc2ZGyuQOu3A3Ld6Od1Pk%2Frs%3Afit%3A240%3A240%2Fg%3Ace%3A0%3A0%2FaHR0cHM6Ly9zdHJlYW0tdG9vbHMuemVub21lZGlhLmNvbS9jb250ZW50L3N0YXRpb25zLzc2ZWM3NzU5LWM4YWUtNDkzOC1iZmI0LTc0MjU2N2FkOTZjNi9pbWFnZS8_dXBkYXRlZD0xNzEzOTA1MzYzMDAw.webp&w=3840&q=100",
    college_image:
      "https://assets-sports.thescore.com/basketball/team/684/logo.png",
    palette: ["#edc96f", "#120f07", "#9d988a", "#7c7c7c"],
  },
  {
    id: 17,
    call_sign: "KAMP",
    broadcast_frequency: "1570",
    audio_url: "https://ice42.securenetsystems.net/KAMP",
    station_url: "https://www.kampstudentradio.com/",
    college_name: "University of Arizona",
    public_private: "Public",
    city: "Tucson",
    state: "AZ",
    station_image:
      "https://www.radio.net/300/kamp-student-radio.jpeg?version=e3ff22a017a45300035cb18ded68e52f",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Arizona_Wildcats_logo.svg/200px-Arizona_Wildcats_logo.svg.png",
    palette: ["#05255d", "#faf9fa", "#bd0f3e", "#dc7c94"],
  },
  {
    id: 19,
    call_sign: "WDBM",
    broadcast_frequency: "88.9",
    audio_url: "http://play.impact89fm.org:8000/impact89fm",
    station_url: "https://impact89fm.org/",
    college_name: "Michigan State University",
    public_private: "Public",
    city: "East Lansing",
    state: "MI",
    station_image:
      "https://impact89fm.org/wp-content/uploads/2015/03/MainLogo-300x3001.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/a/a7/Michigan_State_Athletics_logo.svg/1200px-Michigan_State_Athletics_logo.svg.png",
    palette: ["#1c443c", "#14443c", "#20443c", "#20443c"],
  },
  {
    id: 14,
    call_sign: "WPGU",
    broadcast_frequency: "107.1",
    audio_url: "http://ice64.securenetsystems.net/WPGUFM",
    station_url: "http://illinimedia.org/",
    college_name: "University of Illinois-Urbana-Champaign",
    public_private: "Public",
    city: "Champaign",
    state: "IL",
    station_image:
      "https://media-exp1.licdn.com/dms/image/C4E0BAQGlh_vDeKpWIQ/company-logo_200_200/0/1521359015698?e=2147483647&v=beta&t=NDMNDJlDJXyrHEYyzJsVvSkGbmbveL-WBukDv-NqGx8",
    college_image:
      "https://b.fssta.com/uploads/application/college/team-logos/Illinois.png",
    palette: ["#eb4b24", "#142c4c", "#65383f", "#342c44"],
  },
  {
    id: 16,
    call_sign: "WWVU",
    broadcast_frequency: "91.7",
    audio_url:
      "http://n0a.radiojar.com/56p3rt9eytzuv?1665850857=&rj-tok=AAABg9x8ZxMAGQavy8qgdTpyMQ&rj-ttl=5",
    station_url: "https://u92themoose.com/",
    college_name: "West Virginia University",
    public_private: "Public",
    city: "Morgantown",
    state: "WV",
    station_image:
      "https://cdn-profiles.tunein.com/s23884/images/logog.png?t=159614",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/West_Virginia_Mountaineers_logo.svg/175px-West_Virginia_Mountaineers_logo.svg.png",
    palette: ["#042c54", "#042454", "#082c54", "#082c54"],
  },
  {
    id: 18,
    call_sign: "KFJC",
    broadcast_frequency: "89.7",
    audio_url: "http://netcast.kfjc.org/kfjc-128k-mp3",
    station_url: "https://kfjc.org/",
    college_name: "Foothill College",
    public_private: "Public",
    city: "Los Altos Hills",
    state: "CA",
    station_image:
      "https://kfjc.org/static/images/logos/classic.color.gifs/classic.color.600x470.gif",
    college_image:
      "https://www.wemakescholars.com/admin/uploads/providers/fiG-Xq69emSoKM8DaEpfSu42f90Mo11c.png",
    palette: ["#ac1c2c", "#8e1c24", "#b4202c", "#b02028"],
  },
  {
    id: 26,
    call_sign: "WVFS",
    broadcast_frequency: "89.7",
    audio_url: "http://voice.wvfs.fsu.edu:8000/stream",
    station_url: "https://wvfs.fsu.edu/",
    college_name: "Florida State University",
    public_private: "Public",
    city: "Tallahassee",
    state: "FL",
    station_image:
      "https://naccchart.com/wp-content/uploads/2022/03/wvfs-hi-res_350.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/d/d5/Florida_State_Seminoles_logo.svg/1200px-Florida_State_Seminoles_logo.svg.png",
    palette: ["#452d34", "#ede8db", "#b0a79a", "#b9929c"],
  },
  {
    id: 28,
    call_sign: "WUOG",
    broadcast_frequency: "90.5",
    audio_url: "https://stream.wuog.org/stream",
    station_url: "http://wuog.org/",
    college_name: "University of Georgia",
    public_private: "Public",
    city: "Athens",
    state: "GA",
    station_image:
      "https://bloximages.newyork1.vip.townnews.com/redandblack.com/content/tncms/assets/v3/editorial/6/23/623c3abe-acc1-11e4-ace3-3f1d40ac2f84/54d2a4c19933a.image.jpg?resize=400%2C400",
    college_image:
      "https://content.sportslogos.net/logos/31/687/full/georgia_bulldogs_logo_secondary_2015_sportslogosnet-7979.png",
    palette: ["#040404", "#fafafa", "#af1531", "#848484"],
  },
  {
    id: 8,
    call_sign: "WXTJ",
    broadcast_frequency: "100.1",
    audio_url: "https://streams.wtju.net/wxtj-live.mp3",
    station_url: "https://www.wxtj.fm/",
    college_name: "University of Virginia",
    public_private: "Public",
    city: "Charlottesville",
    state: "VA",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/5/50/WXTJ-LP_2015.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/1/1e/Virginia_Cavaliers_logo.svg/800px-Virginia_Cavaliers_logo.svg.png",
    palette: ["#fb5714", "#0f3057", "#6a3b37", "#4a353c"],
  },
  {
    id: 9,
    call_sign: "WLUR",
    broadcast_frequency: "91.5",
    audio_url: "https://wlur.radioca.st/stream",
    station_url: "https://my.wlu.edu/wlur",
    college_name: "Washington and Lee University",
    public_private: "private",
    city: "Lexington",
    state: "VA",
    station_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkfc-Q2tl8cJ1nHa1OXdfU-fo5-1L714vSWw&s",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Wash-lee_logo_from_NCAA.svg/1200px-Wash-lee_logo_from_NCAA.svg.png",
    palette: ["#093698", "#e6e8ea", "#0e0e0e", "#799fc2"],
  },
  {
    id: 10,
    call_sign: "WTBU",
    broadcast_frequency: "89.3",
    audio_url: "http://wtbu.bu.edu:1800/",
    station_url: "https://sites.bu.edu/wtbu/",
    college_name: "Boston University",
    public_private: "private",
    city: "Boston",
    state: "MA",
    station_image:
      "https://i1.sndcdn.com/avatars-000070779841-52d5la-t500x500.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/1/15/Boston_University_Terriers_logo.svg/800px-Boston_University_Terriers_logo.svg.png",
    palette: ["#ba0613", "#070606", "#f1eff0", "#857c7c"],
  },
  {
    id: 11,
    call_sign: "WKDU",
    broadcast_frequency: "91.7",
    audio_url: "https://streams.wkdu.org/listen.mp3",
    station_url: "https://wkdu.org/",
    college_name: "Drexel University",
    public_private: "Private",
    city: "Philadelphia",
    state: "PA",
    station_image:
      "https://static.tuneyou.com/images/logos/500_500/81/10581/WKDUFM91.7.png",
    college_image:
      "https://drexel.edu/~/media/Images/identity/pageLogos/Drexel_Vertical-stacked_gold.ashx?la=en",
    palette: ["#fcc404", "#ffc404", "#ffc404", "#ffc404"],
  },
  {
    id: 4,
    call_sign: "WCBN",
    broadcast_frequency: "88.3",
    audio_url: "http://floyd.wcbn.org:8000/wcbn-hd.mp3",
    station_url: "http://www.wcbn.org/",
    college_name: "University of Michigan",
    public_private: "public",
    city: "Ann Arbor",
    state: "MI",
    station_image:
      "https://pbs.twimg.com/profile_images/1362092476375310338/ApUeoD54_400x400.jpg",
    college_image: "https://cdn.d1baseball.com/logos/teams/256/michigan.png",
    palette: ["#fcd304", "#fcdc04", "#fcd414", "#f4cc04"],
  },
  {
    id: 5,
    call_sign: "WUTK",
    broadcast_frequency: "90.3",
    audio_url: "https://15233.live.streamtheworld.com/WUTKFM.mp3",
    station_url: "https://wutkradio.com/",
    college_name: "University of Tennessee, Knoxville",
    public_private: "Public",
    city: "Knoxville",
    state: "TN",
    station_image: "https://cdn-radiotime-logos.tunein.com/s23442g.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e3/Tennessee_Volunteers_logo.svg/1200px-Tennessee_Volunteers_logo.svg.png",
    palette: ["#fc8404", "#ff8404", "#ff8404", "#ff8404"],
  },
  {
    id: 12,
    call_sign: "WKNC",
    broadcast_frequency: "88.1",
    audio_url: "https://das-edge14-live365-dal02.cdnstream.com/a45877",
    station_url: "https://wknc.org/",
    college_name: "North Carolina State University",
    public_private: "Public",
    city: "Raleigh",
    state: "NC",
    station_image:
      "https://wknc.org/wp-content/uploads/2020/10/wknc881-bow.png",
    college_image:
      "https://trademarks.ncsu.edu/wp-content/uploads/2016/04/cropped-brick-s-1.png",
    palette: ["#cb262e", "#0c0c0e", "#f6f3f3", "#ec8c9c"],
  },
  {
    id: 13,
    call_sign: "WRUV",
    broadcast_frequency: "90.1",
    audio_url: "http://icecast.uvm.edu:8005/wruv_fm_128",
    station_url: "http://wruv.org/",
    college_name: "University of Vermont",
    public_private: "Public",
    city: "Burlington",
    state: "VT",
    station_image: "https://cdn-radiotime-logos.tunein.com/s22632g.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/3/34/Vermont_Catamounts_logo.svg/1200px-Vermont_Catamounts_logo.svg.png",
    palette: ["#053c0f", "#9d5e0f", "#f7d15c", "#7b8c0c"],
  },
  {
    id: 15,
    call_sign: "KWVA",
    broadcast_frequency: "88.1",
    audio_url: "http://kwvaradio.uoregon.edu:8000/stream/1/",
    station_url: "https://kwva.uoregon.edu/",
    college_name: "University of Oregon",
    public_private: "Public",
    city: "Eugene",
    state: "OR",
    station_image:
      "https://i3.radionomy.com/radios/400/c9d7f1d5-24ed-45e7-b0dd-8fbdd20528e0.jpg",
    college_image:
      "https://assets-sports.thescore.com/football/team/514/logo.png",
    palette: ["#f2eb1c", "#176a42", "#7da434", "#97b62e"],
  },
  {
    id: 27,
    call_sign: "KUNM",
    broadcast_frequency: "89.9",
    audio_url: "https://19273.live.streamtheworld.com/KUNMFM_128.mp3",
    station_url: "https://www.kunm.org/",
    college_name: "University of New Mexico",
    public_private: "Public",
    city: "Albuquerque",
    state: "NM",
    station_image:
      "https://dbs.radioline.fr/pictures/radio_f9e336e70016db25b63ef34b980b8f59/logo200.jpg?size=200",
    college_image:
      "https://coursera-university-assets.s3.amazonaws.com/8b/80ffc01f5011e5bac7a71557814a9f/UNM_Logo_Vert_Coursera.png",
    palette: ["#7b7980", "#cc1344", "#f8f7f7", "#bab4bc"],
  },
  {
    id: 20,
    call_sign: "WUVT",
    broadcast_frequency: "90.7",
    audio_url: "https://stream.wuvt.vt.edu/wuvt-hq.aac",
    station_url: "https://www.wuvt.vt.edu/",
    college_name: "Virginia Tech",
    public_private: "Public",
    city: "Blacksburg",
    state: "VA",
    station_image: "https://cdn-radiotime-logos.tunein.com/s23449g.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Virginia_Tech_Hokies_logo.svg/1200px-Virginia_Tech_Hokies_logo.svg.png",
    palette: ["#a2392c", "#fbfafa", "#ad8790", "#b4949c"],
  },
  {
    id: 21,
    call_sign: "WHUS",
    broadcast_frequency: "91.7",
    audio_url: "http://stream.whus.org:8000/whusfm",
    station_url: "https://whus.org/",
    college_name: "University of Connecticut",
    public_private: "Public",
    city: "Mansfield",
    state: "CT",
    station_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHP4z0afknHEtpmHaK5x7mjIm8QNwPSXOo0v1uOfZ0PxX-ex5TBEqGBGP3byvnvwhEUKM&usqp=CAU",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/b/b0/Connecticut_Huskies_logo.svg/1200px-Connecticut_Huskies_logo.svg.png",
    palette: ["#040c2c", "#eceded", "#e2042c", "#7793ac"],
  },
  {
    id: 22,
    call_sign: "KMNR",
    broadcast_frequency: "89.7",
    audio_url: "https://boombox.kmnr.org/webstream.mp3",
    station_url: "https://kmnr.org/",
    college_name: "Missouri University of Science and Technology",
    public_private: "",
    city: "Rolla",
    state: "MO",
    station_image:
      "https://www.radio.net/images/broadcasts/a5/7b/31601/c300.png",
    college_image:
      "https://www.pinclipart.com/picdir/big/76-766936_mascot-marks-missouri-s-t-athletics-logo-clipart.png",
    palette: ["#eae1cf", "#055535", "#8aa274", "#7cac9c"],
  },
  {
    id: 23,
    call_sign: "WRPI",
    broadcast_frequency: "91.5",
    audio_url: "http://icecast1.wrpi.org:8000/mp3-256.mp3",
    station_url: "https://www.wrpi.org/",
    college_name: "Rensselaer Polytechnic Institute",
    public_private: "Private",
    city: "Troy",
    state: "NY",
    station_image: "https://cdn-radiotime-logos.tunein.com/s22546g.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/RPI_Engineers.svg/1200px-RPI_Engineers.svg.png",
    palette: ["#e3241c", "#242424", "#70241d", "#96241c"],
  },
  {
    id: 24,
    call_sign: "KZUU",
    broadcast_frequency: "90.7",
    audio_url: "https://ice8.securenetsystems.net/KZUU",
    station_url: "https://kzuu.org/",
    college_name: "Washington State University",
    public_private: "Public",
    city: "Pullman",
    state: "WA",
    station_image:
      "https://kzuuorg.files.wordpress.com/2019/03/cropped-full-logo-final-2018.png?w=300",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/0/07/Washington_State_Cougars_logo.svg/800px-Washington_State_Cougars_logo.svg.png",
    palette: ["#fbfafa", "#9c1c34", "#bf727f", "#cc8c9c"],
  },
  {
    id: 25,
    call_sign: "KSPC",
    broadcast_frequency: "88.7",
    audio_url: "https://kspc.radioca.st/stream",
    station_url: "https://kspc.org/",
    college_name: "Claremont Colleges",
    public_private: "Private",
    city: "Claremont",
    state: "CA",
    station_image: "https://kspc.org/wp-content/uploads/2019/10/brand-2.jpg",
    college_image:
      "https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa/500/17.png",
    palette: ["#f5c20d", "#850904", "#f4df7a", "#bc5c1c"],
  },
  {
    id: 29,
    call_sign: "KXUA",
    broadcast_frequency: "88.3",
    audio_url: "https://listen.kxua.com/stream",
    station_url: "https://kxua.uark.edu/",
    college_name: "University of Arkansas",
    public_private: "Public",
    city: "Fayetteville",
    state: "AR",
    station_image:
      "https://spinitron.com/images/Station/13/1389-img_logo.225x225.jpg?v=1568071394",
    college_image:
      "http://sportslogohistory.com/wp-content/uploads/2018/09/arkansas_razorbacks_2014-pres.png",
    palette: ["#9d2233", "#13090b", "#c7c7c7", "#8c8c8c"],
  },
  {
    id: 30,
    call_sign: "KZSC",
    broadcast_frequency: "88.1",
    audio_url: "https://kzscfms1-geckohost.radioca.st/kzschigh",
    station_url: "https://kzsc.org/",
    college_name: "University of California, Santa Cruz",
    public_private: "Public",
    city: "Santa Cruz",
    state: "CA",
    station_image:
      "https://kzsc.org/wp-content/uploads/2022/04/sticker-richarddjames.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/d/dd/Banana_slugs_logo.png",
    palette: ["#fbcc07", "#04143c", "#7c6444", "#6e7084"],
  },
  {
    id: 31,
    call_sign: "WVUM",
    broadcast_frequency: "90.5",
    audio_url: "https://s7.voscast.com:8693/stream",
    station_url: "https://www.wvum.org/",
    college_name: "University of Miami",
    public_private: "Private",
    city: "Miami",
    state: "FL",
    station_image:
      "https://images.squarespace-cdn.com/content/v1/5a822acff09ca44ad247aaea/1531168670297-OSO6W5VE5M51YU4E3X07/static1.squarespace.png",
    college_image:
      "https://a5e8126a499f8a963166-f72e9078d72b8c998606fd6e0319b679.ssl.cf5.rackcdn.com/images/sports-leagues/ncaa-acc-miami-logo.png",
    palette: ["#f35b04", "#045333", "#f8f6f3", "#689484"],
  },
  {
    id: 32,
    call_sign: "KFSR",
    broadcast_frequency: "90.7",
    audio_url: "https://icecast.auxiliary.com/kfsr",
    station_url: "http://kfsr.org/",
    college_name: "California State University, Fresno",
    public_private: "Public",
    city: "Fresno",
    state: "CA",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/f/fd/KFSR-FM_90.7_radio_logo.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Fresno_State_Bulldogs_baseball_logo.svg/1118px-Fresno_State_Bulldogs_baseball_logo.svg.png",
    palette: ["#142b4b", "#c31434", "#7d1c3c", "#9d1635"],
  },
  {
    id: 33,
    call_sign: "WSBF",
    broadcast_frequency: "88.1",
    audio_url: "https://wsbf.net/stream/high",
    station_url: "https://wsbf.net/#!/",
    college_name: "Clemson University",
    public_private: "Public",
    city: "Clemson",
    state: "SC",
    station_image: "https://wsbf.net/images/wsbflady_250.png",
    college_image:
      "https://sportslogohistory.com/wp-content/uploads/2018/01/clemson_tigers_1977-pres_s.png",
    palette: ["#f36b2c", "#532780", "#6f4d9b", "#fcbfa5"],
  },
  {
    id: 34,
    call_sign: "WREK",
    broadcast_frequency: "91.1",
    audio_url: "http://streaming.wrek.org:8000/wrek_live-128kb",
    station_url: "https://www.wrek.org/",
    college_name: "Georgia Institute of Technology",
    public_private: "Public",
    city: "Atlanta",
    state: "GA",
    station_image: "https://avatars3.githubusercontent.com/u/2118908?s=280&v=4",
    college_image: "https://content.sportslogos.net/logos/31/690/full/2491.gif",
    palette: ["#251d1d", "#e8b12c", "#f6f6f6", "#6c6c6c"],
  },
  {
    id: 36,
    call_sign: "CITR",
    broadcast_frequency: "101.9",
    audio_url: "https://live.citr.ca/live.aac",
    station_url: "https://www.citr.ca/",
    college_name: "University of British Columbia",
    public_private: "Public",
    city: "Vancouver",
    state: "BC",
    station_image:
      "https://d3wo5wojvuv7l.cloudfront.net/t_twitter_card/images.spreaker.com/original/96ede5aff67a67e3dac827c52c7bd2f6.jpg",
    college_image:
      "https://cdn.freebiesupply.com/logos/large/2x/ubc-logo-png-transparent.png",
    palette: ["#d4b42c", "#24246c", "#acb84c", "#50704c"],
  },
  {
    id: 37,
    call_sign: "WTUL",
    broadcast_frequency: "91.5",
    audio_url: "http://129.81.156.83:8000/stream",
    station_url: "https://www.wtulneworleans.com/",
    college_name: "Tulane University",
    public_private: "Private",
    city: "New Orleans",
    state: "LA",
    station_image:
      "https://i1.sndcdn.com/avatars-000107068841-z5wmaw-t500x500.jpg",
    college_image:
      "https://content.sportslogos.net/logos/34/877/full/tulane_green_wave_logo_primary_1986_sportslogosnet-7875.png",
    palette: ["#fbfbfb", "#04644c", "#43c3e3", "#74ac9c"],
  },
  {
    id: 38,
    call_sign: "KDVS",
    broadcast_frequency: "90.3",
    audio_url: "https://archives.kdvs.org/stream",
    station_url: "https://kdvs.org/",
    college_name: "University of California, Davis",
    public_private: "Public",
    city: "Davis",
    state: "CA",
    station_image:
      "https://www.radio.net/images/broadcasts/e0/ec/29376/c175.png",
    college_image:
      "https://content.sportslogos.net/logos/30/1831/full/california_davis_aggies_logo_primary_1959_sportslogosnet-8865.png",
    palette: ["#042c53", "#cb9304", "#9c7c14", "#6c6628"],
  },
  {
    id: 39,
    call_sign: "WUCF",
    broadcast_frequency: "89.9",
    audio_url: "https://peridot.streamguys1.com:7835/WUCF",
    station_url: "https://www.wucf.org/",
    college_name: "University of Central Florida",
    public_private: "Public",
    city: "Orlando",
    state: "FL",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/1/17/WUCF_FM_%282019%29.svg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/UCF_Knights_logo.svg/1200px-UCF_Knights_logo.svg.png",
    palette: ["#040404", "#b3a36c", "#fbfbfb", "#5c4c34"],
  },
  {
    id: 40,
    call_sign: "WERS",
    broadcast_frequency: "88.9",
    audio_url: "https://24413.live.streamtheworld.com/WERSFM_SC",
    station_url: "https://wers.org/",
    college_name: "Emerson College",
    public_private: "Private",
    city: "Boston",
    state: "MA",
    station_image:
      "https://wers.org/wp-content/uploads/2017/12/WERS_socialmedia_nowaves-04.png",
    college_image:
      "https://www.ncaa.com/sites/default/files/images/logos/schools/bgl/emerson.svg",
    palette: ["#facb37", "#252424", "#7d7553", "#846c2c"],
  },
  {
    id: 41,
    call_sign: "WKPS",
    broadcast_frequency: "90.7",
    audio_url:
      "https://n0a.radiojar.com/k9uw1tyr7x8uv?rj-ttl=5&rj-tok=AAABhCVt97AAAWGizcTmTKqRJQ",
    station_url: "https://www.thelion.fm/",
    college_name: "Penn State University",
    public_private: "Public",
    city: "State College",
    state: "PA",
    station_image:
      "https://www.radio.net/images/broadcasts/10/84/28658/c300.png",
    college_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjJoR3uzS2GS_KVfRbu6xI4yrvqto2iivTNQ&s",
    palette: ["#f2f3f6", "#062d64", "#7c8cb2", "#7182ac"],
  },
  {
    id: 42,
    call_sign: "KZSU",
    broadcast_frequency: "90.1",
    audio_url: "http://kzsu-streams.stanford.edu/kzsu-1-128.mp3",
    station_url: "http://kzsu.stanford.edu/",
    college_name: "Stanford University",
    public_private: "Private",
    city: "Stanford",
    state: "CA",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Kzsuheartlogo.svg/1200px-Kzsuheartlogo.svg.png",
    college_image:
      "http://identity.stanford.edu/wp-content/uploads/sites/3/2020/07/block-s-right.png",
    palette: ["#8d1616", "#e9e4e4", "#057464", "#c4848c"],
  },
  {
    id: 43,
    call_sign: "WPRB",
    broadcast_frequency: "103.3",
    audio_url: "https://wprb.streamguys1.com/live",
    station_url: "https://wprb.com/",
    college_name: "Princeton University",
    public_private: "Private",
    city: "Princeton",
    state: "NJ",
    station_image:
      "https://playlists.wprb.com/images/Station/18/1895-img_logo.225x225.png?v=1621258861",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Princeton_Tigers_logo.svg/895px-Princeton_Tigers_logo.svg.png",
    palette: ["#040404", "#fb6304", "#fbfafa", "#999898"],
  },
  {
    id: 44,
    call_sign: "KTCU",
    broadcast_frequency: "88.7",
    audio_url: "http://ktcustream01.is.tcu.edu/ktcu",
    station_url: "https://ktcu.tcu.edu/",
    college_name: "Texas Christian University",
    public_private: "",
    city: "Fort Worth",
    state: "TX",
    station_image:
      "https://www.radio.net/images/broadcasts/5e/db/17703/2/c300.png",
    college_image:
      "https://sportslogohistory.com/wp-content/uploads/2021/07/tcu_horned_frogs_1994-1997.png",
    palette: ["#542c6c", "#582c6c", "#582c6c", "#582c6c"],
  },
  {
    id: 45,
    call_sign: "CFUV",
    broadcast_frequency: "101.9",
    audio_url: "https://ais-sa1.streamon.fm/7132_64k.aac",
    station_url: "http://cfuv.uvic.ca/cms/",
    college_name: "University of Victoria",
    public_private: "Public",
    city: "Victoria",
    state: "BC",
    station_image:
      "https://i1.sndcdn.com/avatars-000445307787-562tuu-t500x500.jpg",
    college_image:
      "https://apply.educationplannerbc.ca/assets/institutions/uvic/logo/UVIC_logo.png",
    palette: ["#f5dcd3", "#292129", "#8aa6d0", "#2371b1"],
  },
  {
    id: 46,
    call_sign: "CHUO",
    broadcast_frequency: "89.1",
    audio_url: "https://stream.statsradio.com:8102/stream",
    station_url: "https://chuo.fm/",
    college_name: "University of Ottawa",
    public_private: "Public",
    city: "Ottawa",
    state: "ON",
    station_image:
      "https://chuo.fm/wp-content/uploads/2017/03/CHUO-Logo-web600x600-06.png",
    college_image:
      "https://logowik.com/content/uploads/images/university-of-ottawa5840.jpg",
    palette: ["#040404", "#080404", "#080404", "#080404"],
  },
  {
    id: 47,
    call_sign: "KOLN",
    broadcast_frequency: "100.0",
    audio_url: "https://live.koelncampus.com/live",
    station_url: "https://www.koelncampus.com/",
    college_name: "University of Cologne",
    public_private: "Public",
    city: "Cologne",
    state: "Germany",
    station_image:
      "https://pbs.twimg.com/profile_images/999195818480488449/zT5K6ZFh_400x400.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Siegel_Uni_K%C3%B6ln_%28grau%29.svg/1200px-Siegel_Uni_K%C3%B6ln_%28grau%29.svg.png",
    palette: ["#447ca4", "#f6f8f9", "#85a7c5", "#9cbccc"],
  },
  {
    id: 48,
    call_sign: "WHRB",
    broadcast_frequency: "95.3",
    audio_url: "https://stream.whrb.org/whrb-he-aac",
    station_url: "https://www.whrb.org/",
    college_name: "Harvard University",
    public_private: "Private",
    city: "Cambridge",
    state: "MA",
    station_image:
      "https://is5-ssl.mzstatic.com/image/thumb/Purple118/v4/07/c5/8a/07c58abd-ce85-0f16-e320-fe29fe92f960/source/512x512bb.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/Harvard_Crimson_logo.svg/1737px-Harvard_Crimson_logo.svg.png",
    palette: ["#151d24", "#f9f9f9", "#a6883c", "#a31c33"],
  },
  {
    id: 49,
    call_sign: "WUML",
    broadcast_frequency: "91.5",
    audio_url: "http://cp12.shoutcheap.com:8306/stream",
    station_url: "https://www.wuml.org/",
    college_name: "University of Massachusetts Lowell",
    public_private: "Public",
    city: "Lowell",
    state: "MA",
    station_image:
      "https://images.squarespace-cdn.com/content/v1/5d755b2788fb8857b5fb99af/94762cdb-a49f-4324-9817-f46094bb10da/Covid+WUML+Logo+with+shadow.png",
    college_image:
      "https://goriverhawks.com/images/2021/8/20/PrimaryLogo_NoStroke.png",
    palette: ["#056bb3", "#d32434", "#f9fafb", "#74acd4"],
  },
  {
    id: 50,
    call_sign: "KTUH",
    broadcast_frequency: "90.1",
    audio_url: "https://stream.ktuh.org:8001/stream",
    station_url: "https://ktuh.org/",
    college_name: "University of Hawaii at Manoa",
    public_private: "Public",
    city: "Manoa",
    state: "HI",
    station_image: "https://www.pritchettcartoons.com/logos/ktuh.jpg",
    college_image: "https://hawaiiathletics.com/images/logos/v13.png",
    palette: ["#d7d8db", "#194634", "#86ac9c", "#8cb4a4"],
  },
  {
    id: 51,
    call_sign: "KTRU",
    broadcast_frequency: "96.1",
    audio_url: "https://ice41.securenetsystems.net/KTRU64M",
    station_url: "https://ktru.org/",
    college_name: "Rice University",
    public_private: "Private",
    city: "Houston",
    state: "TX",
    station_image: "https://cdn-radiotime-logos.tunein.com/s257013g.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Rice_Owls_logo.svg/1200px-Rice_Owls_logo.svg.png",
    palette: ["#04245c", "#041c5c", "#08245c", "#08245c"],
  },
  {
    id: 52,
    call_sign: "KURE",
    broadcast_frequency: "88.5",
    audio_url: "http://kure-network.stuorg.iastate.edu:8000/KUREBroadcast",
    station_url: "https://kure.stuorg.iastate.edu/",
    college_name: "Iowa State University",
    public_private: "Public",
    city: "Ames",
    state: "IA",
    station_image:
      "https://i1.sndcdn.com/avatars-000131375076-3zgb62-t500x500.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Iowa_State_Cyclones_logo.svg/640px-Iowa_State_Cyclones_logo.svg.png",
    palette: ["#8e2334", "#fbd03f", "#cd7332", "#bc844c"],
  },
  {
    id: 53,
    call_sign: "WVJC",
    broadcast_frequency: "89.1",
    audio_url: "https://server02.nkstreaming.com:8122/stream",
    station_url: "http://www.bashradio.com/",
    college_name: "Wabash Valley College",
    public_private: "Public",
    city: "Mount Carmel",
    state: "IL",
    station_image:
      "https://static.wixstatic.com/media/6ed5e9_8981c37b2ed84fed82e0692f6b08a37c~mv2.png/v1/crop/x_81,y_70,w_624,h_472/fill/w_560,h_424,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/The%20BASH%20Logo.png",
    college_image:
      "https://gomcpanthers.com/images/logos/wabash_valley_logo.png?width=200&height=200",
    palette: ["#120d0e", "#ec163c", "#869c9f", "#747474"],
  },
  {
    id: 54,
    call_sign: "WXDU",
    broadcast_frequency: "88.7",
    audio_url: "http://weeping.wxdu.duke.edu:8000/wxdu128.mp3",
    station_url: "https://www.wxdu.org/",
    college_name: "Duke University",
    public_private: "Private",
    city: "Durham",
    state: "NC",
    station_image:
      "https://pbs.twimg.com/profile_images/633378260416643072/lKReF_R3_400x400.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Duke_Athletics_logo.svg/1200px-Duke_Athletics_logo.svg.png",
  },
  {
    id: 55,
    call_sign: "KCSU",
    broadcast_frequency: "90.5",
    audio_url: "https://listen.kcsufm.com/stream",
    station_url: "https://kcsufm.com/",
    college_name: "Colorado State University",
    public_private: "Public",
    city: "Fort Collins",
    state: "CO",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/3/37/KCSU_logo-Multi_SP2022_%281%29.png",
    college_image:
      "https://brand.colostate.edu/wp-content/uploads/sites/47/2019/01/CSU-Ram-357.png",
    palette: ["#1c4c2c", "#fbfbfb", "#8ca494", "#9cb4a4"],
  },
  {
    id: 56,
    call_sign: "WVPH",
    broadcast_frequency: "90.3",
    audio_url: "http://rsc417c1.rutgers.edu:8000/WRSU-FM",
    station_url: "https://www.thecore.fm/",
    college_name: "Rutgers University",
    public_private: "Public",
    city: "New Brunswick",
    state: "NJ",
    station_image:
      "https://cdn-profiles.tunein.com/s23615/images/logog.jpg?t=1",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Rutgers_Scarlet_Knights_logo.svg/1153px-Rutgers_Scarlet_Knights_logo.svg.png",
  },
  {
    id: 57,
    call_sign: "WMUC",
    broadcast_frequency: "90.5",
    audio_url: "https://wmuc.umd.edu:8443/wmuc-hq",
    station_url: "http://www.wmuc.umd.edu/",
    college_name: "University of Maryland",
    public_private: "Public",
    city: "College Park",
    state: "MD",
    station_image:
      "https://pbs.twimg.com/profile_images/910138549835530240/wBQGp9Nt_400x400.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Maryland_Terrapins_logo.svg/1200px-Maryland_Terrapins_logo.svg.png",
    palette: ["#e31c33", "#040404", "#fbd617", "#bc8c94"],
  },
  {
    id: 58,
    call_sign: "KCPR",
    broadcast_frequency: "91.3",
    audio_url: "https://ice9.securenetsystems.net/KCPR1",
    station_url: "https://kcpr.org/",
    college_name: "Calfornia Polytechnic State University, San Luis Obispo",
    public_private: "Public",
    city: "San Luis Obispo",
    state: "CA",
    station_image:
      "https://alexullrich.files.wordpress.com/2013/05/kcpr_logo_matthew_schwartz_bw.jpg?w=640",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/2/29/Calpolylogosports.png",
    palette: ["#063d34", "#eacf83", "#fafaf8", "#89784d"],
  },
  {
    id: 59,
    call_sign: "WVUD",
    broadcast_frequency: "91.3",
    audio_url: "http://128.175.76.123:8000/stream/1/",
    station_url: "https://www.wvud.org/",
    college_name: "University of Delaware",
    public_private: "Public",
    city: "Newark",
    state: "DE",
    station_image: "https://www.wvud.org/files/2017/04/cropped-WVUD600600.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/0/0a/University_of_Delaware_Wordmark.png",
    palette: ["#096ead", "#fbdc05", "#f2f6f5", "#78a05c"],
  },
  {
    id: 60,
    call_sign: "WNSB",
    broadcast_frequency: "91.1",
    audio_url: "http://27033.live.streamtheworld.com:3690/WNSBFM.mp3",
    station_url: "https://www.nsu.edu/WNSB",
    college_name: "Norfolk State University",
    public_private: "Public",
    city: "Norfolk",
    state: "VA",
    station_image:
      "http://cdn-profiles.tunein.com/s21544/images/logod.png?t=637800282700000000",
    college_image:
      "https://content.sportslogos.net/logos/33/774/full/norfolk_state_spartans_logo_secondary_19991057.png",
    palette: ["#047c54", "#fbfbfa", "#f3a405", "#74b49c"],
  },
  {
    id: 61,
    call_sign: "WRAS",
    broadcast_frequency: "88.5",
    audio_url: "https://15233.live.streamtheworld.com/WRASFM.mp3",
    station_url: "https://wras.org/",
    college_name: "Georgia State University",
    public_private: "Public",
    city: "Atlanta",
    state: "GA",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/a/ae/WRAS_Logo.png",
    college_image:
      "https://commkit.gsu.edu/files/2021/05/GSU-Athletics-Primary-3C-RGB.png",
    palette: ["#e7bbc8", "#043ca4", "#6484cc", "#2a59b5"],
  },
  {
    id: 62,
    call_sign: "KXLU",
    broadcast_frequency: "88.9",
    audio_url: "https://kxlu.streamguys1.com/kxlu-hi",
    station_url: "https://kxlu.com/",
    college_name: "Loyola Marymount University",
    public_private: "Private",
    city: "Los Angeles",
    state: "CA",
    station_image:
      "http://cdn-profiles.tunein.com/s26509/images/logod.jpg?t=637919750680000000",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Loyola_Marymount_Lions_logo.svg/1200px-Loyola_Marymount_Lions_logo.svg.png",
    palette: ["#042c54", "#7c041c", "#b1b0b8", "#3f5a77"],
  },
  {
    id: 63,
    call_sign: "KTSW",
    broadcast_frequency: "89.9",
    audio_url: "https://ktswlive.txstate.edu:8102/listen",
    station_url: "https://ktsw.txst.edu/",
    college_name: "Texas State University",
    public_private: "Public",
    city: "San Marcos",
    state: "TX",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/a/a6/KTSW_89.9.jpeg/220px-KTSW_89.9.jpeg",
    college_image:
      "https://www.txstate.edu/cache5775023272c6b91d422e57ec36e9f40a/imagehandler/scaler/gato-docs.its.txstate.edu/jcr:c11449cd-1dbd-494e-a0c3-f04efc16a480/Secondary_RGB_MRN-GLD.png?mode=fit&width=750",
    palette: ["#ab9353", "#541c1c", "#724531", "#84543c"],
  },
  {
    id: 64,
    call_sign: "CJIQ",
    broadcast_frequency: "88.3",
    audio_url: "https://conestoga.leanstream.co/CJIQFM-MP3",
    station_url: "https://www.cjiqfm.com/",
    college_name: "Conestoga College",
    public_private: "Public",
    city: "Kitchener",
    state: "ON",
    station_image:
      "https://cdn-profiles.tunein.com/s12240/images/logog.png?t=154158",
    college_image:
      "https://workforceplanningboard.org/wp-content/uploads/2020/03/Conestoga_College_logo.svg_-300x179-1.png",
    palette: ["#ba9a63", "#040404", "#6c5c3c", "#2c5434"],
  },
  {
    id: 66,
    call_sign: "KRUI",
    broadcast_frequency: "89.7",
    audio_url: "http://krui.student-services.uiowa.edu:8000/listen",
    station_url: "https://krui.fm/",
    college_name: "University of Iowa",
    public_private: "Public",
    city: "Iowa City",
    state: "IA",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/8/87/KRUI-FM_logo.jpg",
    college_image:
      "https://1000logos.net/wp-content/uploads/2021/06/Iowa-Hawkeyes-logo.png",
    palette: ["#fad40a", "#040404", "#04040c", "#7c6404"],
  },
  {
    id: 67,
    call_sign: "KUOM",
    broadcast_frequency: "100.7",
    audio_url: "http://radiokstreams.cce.umn.edu:8256/;?type=http",
    station_url: "https://www.radiok.org/",
    college_name: "University of Minnesota",
    public_private: "Public",
    city: "Minneapolis",
    state: "MN",
    station_image:
      "https://play-lh.googleusercontent.com/um-Go6BcxvbWvnMsZGe5ygqBDugKu1Dl8iqPywY2cqC3ywRmF0eVRJuxbvDl8Rmdp2M",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/University_of_Minnesota_Logo.svg/2560px-University_of_Minnesota_Logo.svg.png",
    palette: ["#fbcb33", "#7c041c", "#a84a24", "#bc5d24"],
  },
  {
    id: 68,
    call_sign: "WPTS",
    broadcast_frequency: "92.1",
    audio_url: "http://audio.wpts.pitt.edu:8000/wpts_live_128s.mp3",
    station_url: "https://wptsradio.org/",
    college_name: "University of Pittsburgh",
    public_private: "Public",
    city: "Pittsburgh",
    state: "PA",
    station_image:
      "https://i.iheart.com/v3/re/assets.streams/65aea27dbf0c3bda23d8ce93?ops=fit(240%2C240)",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Pitt_Panthers_wordmark.svg/1200px-Pitt_Panthers_wordmark.svg.png",
    palette: ["#faba1c", "#043493", "#57626b", "#77745c"],
  },
  {
    id: 69,
    call_sign: "WMFO",
    broadcast_frequency: "91.5",
    audio_url: "http://webstream.wmfo.org/;?type=http",
    station_url: "https://www.wmfo.org/",
    college_name: "Tufts University",
    public_private: "Private",
    city: "Somerville",
    state: "MA",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/7/79/WMFO_91.5FM_Station_Logo.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/b/b4/Tufts_Jumbos_logo.svg/1200px-Tufts_Jumbos_logo.svg.png",
    palette: ["#c8c2bb", "#3c8cdb", "#89a6c7", "#66ace1"],
  },
  {
    id: 70,
    call_sign: "KRLX",
    broadcast_frequency: "88.1",
    audio_url: "http://137.22.31.174:8000/stream",
    station_url: "https://content.krlx.org/",
    college_name: "Carleton College",
    public_private: "Private",
    city: "Northfield",
    state: "MN",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/6/62/KRLXLogo.png",
    college_image:
      "https://athletics.carleton.edu/images/logos/Carleton-College.png",
    palette: ["#f2c848", "#10437f", "#8c9ca8", "#847c44"],
  },
  {
    id: 71,
    call_sign: "CKCU",
    broadcast_frequency: "93.1",
    audio_url: "https://stream2.statsradio.com:8124/stream",
    station_url: "https://www.ckcufm.com/",
    college_name: "Carleton University",
    public_private: "Public",
    city: "Ottawa",
    state: "ON",
    station_image: "https://cdn-radiotime-logos.tunein.com/s24763g.png",
    college_image:
      "https://first-studyabroad.com/wp-content/uploads/2021/06/1550776205468.png",
    palette: ["#f9f9f9", "#090a0a", "#e43c3c", "#ec7c74"],
  },
  {
    id: 72,
    call_sign: "WUNH",
    broadcast_frequency: "91.3",
    audio_url: "http://s9.viastreaming.net:9000/;",
    station_url: "https://www.unh.edu/wunh/",
    college_name: "University of New Hampshire",
    public_private: "Public",
    city: "Durham",
    state: "NH",
    station_image:
      "https://pbs.twimg.com/media/EmGqlP1XEAI7mjr?format=jpg&name=large",
    college_image:
      "https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa/500/160.png",
    palette: ["#06265d", "#dcddde", "#647494", "#687c94"],
  },
  {
    id: 73,
    call_sign: "WMSR",
    broadcast_frequency: "630",
    audio_url: "https://s2.radio.co/s20123bfa0/listen",
    station_url: "https://www.redhawkradio.com/",
    college_name: "Miami University",
    public_private: "Public",
    city: "Oxford",
    state: "OH",
    station_image:
      "https://se-images.campuslabs.com/clink/images/82dc7d8f-f40b-4487-b193-dec9779b3ecf95317a62-63ab-4d9b-8cc1-f2dadecf2b46.png?preset=med-sq",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Miami_Redhawks_logo.svg/1200px-Miami_Redhawks_logo.svg.png",
    palette: ["#040404", "#b31c2b", "#520d15", "#841422"],
  },
  {
    id: 74,
    call_sign: "WBRU",
    broadcast_frequency: "95.5",
    audio_url: "https://s3.radio.co/s115121de1/listen",
    station_url: "https://www.wbru.com/",
    college_name: "Brown University",
    public_private: "Private",
    city: "Providence",
    state: "RI",
    station_image:
      "https://freight.cargo.site/t/original/i/b86d671e692615bd0c7e33aeb217c4409de58622161d8f48523c5a4da7a9710b/logo-png.png",
    college_image:
      "https://rihebc.com/wp-content/uploads/2022/04/Brown_University.png",
    palette: ["#0a0909", "#e9e9e9", "#dc2128", "#747474"],
  },
  {
    id: 75,
    call_sign: "WZBC",
    broadcast_frequency: "90.3",
    audio_url: "https://stream.wzbc.org/wzbc",
    station_url: "https://www.wzbc.org/",
    college_name: "Boston College",
    public_private: "Private",
    city: "Newton",
    state: "MA",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/3/37/WZBC903.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/9/96/Boston_College_Eagles_logo.svg/1200px-Boston_College_Eagles_logo.svg.png",
    palette: ["#5e212d", "#ddceab", "#ac8c7b", "#948c8c"],
  },
  {
    id: 76,
    call_sign: "WIUP",
    broadcast_frequency: "90.1",
    audio_url: "http://144.80.16.164:8000/xstream",
    station_url: "http://www.wiupfm.org/",
    college_name: "Indiana University of Pennsylvania",
    public_private: "Public",
    city: "Indiana",
    state: "PA",
    station_image:
      "https://www.radio.net/images/broadcasts/81/e2/28619/c300.png",
    college_image:
      "https://www.iup.edu/_assets/images/footer/iup-artmark-crimson.png",
    palette: ["#9c1c34", "#a01c34", "#a01c34", "#a01c34"],
  },
  {
    id: 77,
    call_sign: "WRUW",
    broadcast_frequency: "91.1",
    audio_url: "https://wruw-stream.wruw.org/hls/stream.m3u8",
    station_url: "https://wruw.org/",
    college_name: "Case Western Reserve University",
    public_private: "Private",
    city: "Cleveland",
    state: "OH",
    station_image:
      "https://yt3.ggpht.com/ytc/AMLnZu9sBQKzhnyi-feCpAHK_LR8cxrkBPgA2OR3lVD3=s900-c-k-c0x00ffffff-no-rj",
    college_image:
      "https://case.edu/brand/sites/default/files/styles/large/public/2023-05/Case-Western-Reserve-University-Seal_RGB-Blue_0.jpg?itok=-nCVOa7R",
    palette: ["#223e4e", "#939ea6", "#738493", "#687c8c"],
  },
  {
    id: 78,
    call_sign: "CFRC",
    broadcast_frequency: "101.9",
    audio_url: "http://audio.cfrc.ca:8000/;",
    station_url: "https://www.cfrc.ca/",
    college_name: "Queen's University",
    public_private: "Public",
    city: "Kingston",
    state: "ON",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/0/0a/CFRC_Primary_Station_Logo.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Queen%27s_Golden_Gaels_Logo.svg/1200px-Queen%27s_Golden_Gaels_Logo.svg.png",
    palette: ["#fbbb14", "#09345a", "#bb1434", "#fcfbf8"],
  },
  {
    id: 79,
    call_sign: "KANM",
    broadcast_frequency: "88.1",
    audio_url: "https://icecast.kanm.org/listen",
    station_url: "https://kanm.tamu.edu/#/",
    college_name: "Texas A&M University",
    public_private: "Public",
    city: "College Station",
    state: "TX",
    station_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHFk-XFo-aNNa9dxJCbzpS3Dkdul82_oCZQpSGHhFbQw&s",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Texas_A%26M_University_logo.svg/1246px-Texas_A%26M_University_logo.svg.png",
    palette: ["#540404", "#fbfbfb", "#a47c7c", "#a47474"],
  },
  {
    id: 80,
    call_sign: "WRFL",
    broadcast_frequency: "88.1",
    audio_url: "https://wrfl.fm:8002/stream/1",
    station_url: "https://wrfl.fm/",
    college_name: "University of Kentucky",
    public_private: "Public",
    city: "Lexington",
    state: "KY",
    station_image:
      "https://wrfl.fm/wp-content/uploads/2022/05/wrfl-website-logo.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/b/b6/Kentucky_Wildcats_logo.svg",
    palette: ["#f8f9fa", "#0434a4", "#738ccc", "#5276c0"],
  },
  {
    id: 81,
    call_sign: "WXPN",
    broadcast_frequency: "88.9",
    audio_url: "https://wxpn.xpn.org/xpnmp3hi",
    station_url: "https://xpn.org/",
    college_name: "University of Pennsylvania",
    public_private: "Private",
    city: "Philadelphia",
    state: "PA",
    station_image:
      "https://pbs.twimg.com/profile_images/1337777242215165952/9OqjjiJI_400x400.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Penn_Quakers_logo.svg/1200px-Penn_Quakers_logo.svg.png",
    palette: ["#fbfbfb", "#051d5c", "#9c0404", "#7384a4"],
  },
  {
    id: 82,
    call_sign: "WIUX",
    broadcast_frequency: "99.1",
    audio_url: "https://ice25.securenetsystems.net/WIUXLP",
    station_url: "https://www.wiux.org/",
    college_name: "Indiana University",
    public_private: "Public",
    city: "Bloomington",
    state: "IN",
    station_image:
      "https://pbs.twimg.com/profile_images/1313677178811416578/x3DEBHHZ_400x400.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/Indiana_Hoosiers_logo.svg/1280px-Indiana_Hoosiers_logo.svg.png",
    palette: ["#9c0404", "#a00404", "#a00404", "#a00404"],
  },
  {
    id: 83,
    call_sign: "WFUV",
    broadcast_frequency: "90.7",
    audio_url: "https://onair.wfuv.org/onair-hi",
    station_url: "https://wfuv.org/",
    college_name: "Fordham University",
    public_private: "Private",
    city: "New York",
    state: "NY",
    station_image: "https://wfuv.org/themes/custom/wfuv/logo.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/b/bb/Fordham_Rams_logo.svg/1200px-Fordham_Rams_logo.svg.png",
    palette: ["#e8e7e7", "#050505", "#83053c", "#717171"],
  },
  {
    id: 84,
    call_sign: "WECS",
    broadcast_frequency: "90.1",
    audio_url: "https://s3.radio.co/s8d7d6d8d5/listen",
    station_url: "http://wecsfm.com/",
    college_name: "Eastern Connecticut State University",
    public_private: "Public",
    city: "Windham",
    state: "CT",
    station_image: "https://d371i8ihhgym7w.cloudfront.net/108590177.png",
    college_image:
      "https://teamlocker.s3.amazonaws.com/uploads/4da1a5cc9c5e530393e4e0e00051e8d325c0d2edSLD24_EasternCT_HAG52_allgear.png",
    palette: ["#d2d2d9", "#08234e", "#830d2c", "#8c6776"],
  },
  {
    id: 85,
    call_sign: "KVSC",
    broadcast_frequency: "88.1",
    audio_url: "https://corn.kvsc.org/broadband",
    station_url: "https://www.kvsc.org/",
    college_name: "St. Cloud State University",
    public_private: "Public",
    city: "St. Cloud",
    state: "MN",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/1/1e/Kvsc_official_logo_2009.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/St._Cloud_State_Huskies_logo.svg/1200px-St._Cloud_State_Huskies_logo.svg.png",
    palette: ["#fafafa", "#040404", "#a3050d", "#cc7c84"],
  },
  {
    id: 86,
    call_sign: "WBER",
    broadcast_frequency: "90.5",
    audio_url: "http://wber-ice-encoder.monroe.edu/wber-high.mp3",
    station_url: "https://wber.org/",
    college_name: "BOCES Monroe",
    public_private: "Public",
    city: "Rochester",
    state: "NY",
    station_image:
      "https://www.brandtatorship.com/wp-content/uploads/2020/05/WBER-Logo-300x300-1.png",
    college_image:
      "https://www.monroe.edu/cms/lib/NY02216770/Centricity/Shared/Logos/monroe_symbol_fullcolor.png",
    palette: ["#f8faf9", "#335f78", "#84c757", "#7c8490"],
  },
  {
    id: 87,
    call_sign: "WSTB",
    broadcast_frequency: "88.9",
    audio_url: "https://us2.maindigitalstream.com/ssl/WSTB",
    station_url: "https://www.rock889.com/",
    college_name: "Streetsboro City School District",
    public_private: "Public",
    city: "Streetsboro",
    state: "OH",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/c/c5/88.9_The_AlterNation_logo.png",
    college_image:
      "https://www.scsrockets.org/wp-content/uploads/2020/09/cropped-SCS-Rockets-Logo-2020.png",
    palette: ["#fbd304", "#044c94", "#044c8c", "#04448c"],
  },
  {
    id: 88,
    call_sign: "WILY",
    broadcast_frequency: "90.1",
    audio_url: "https://azuracast.wileyradio.org/listen/wiley_radio/radio.mp3",
    station_url: "https://wileyradio.org/",
    college_name: "Purdue University",
    public_private: "Public",
    city: "West Lafayette",
    state: "IN",
    station_image:
      "https://i1.sndcdn.com/avatars-000339989542-iypqtj-t500x500.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Purdue_Boilermakers_logo.svg/1200px-Purdue_Boilermakers_logo.svg.png",
    palette: ["#040404", "#c0b293", "#6b6564", "#645c5c"],
  },
  {
    id: 89,
    call_sign: "WYBC",
    broadcast_frequency: "94.3",
    audio_url: "https://wybcx-stream.creek.org/stream",
    station_url: "https://wybc.com/",
    college_name: "Yale University",
    public_private: "Private",
    city: "New Haven",
    state: "CT",
    station_image: "https://cdn-radiotime-logos.tunein.com/s24122g.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2b/Yale_Bulldogs_script.svg/1200px-Yale_Bulldogs_script.svg.png",
    palette: ["#0c2444", "#f9f9f9", "#7f8c9c", "#8494a4"],
  },
  {
    id: 90,
    call_sign: "WVBR",
    broadcast_frequency: "93.5",
    audio_url: "https://ais-edge51-live365-dal02.cdnstream.com/a52371",
    station_url: "https://www.cornellradio.com/",
    college_name: "Cornell University",
    public_private: "Private",
    city: "Ithaca",
    state: "NY",
    station_image:
      "https://chambermaster.blob.core.windows.net/images/customers/1313/members/12961/photos/GALLERY_MAIN/WVBR_record_6.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/9/9b/Cornell_Big_Red_logo.svg/1200px-Cornell_Big_Red_logo.svg.png",
    palette: ["#923523", "#040404", "#fbfafa", "#d89b9b"],
  },
  {
    id: 91,
    call_sign: "WKCR",
    broadcast_frequency: "89.9",
    audio_url: "https://wkcr.streamguys1.com/live",
    station_url: "https://www.cc-seas.columbia.edu/wkcr/",
    college_name: "Columbia University",
    public_private: "Private",
    city: "New York",
    state: "NY",
    station_image:
      "https://cdn-profiles.tunein.com/s30119/images/logog.jpg?t=1",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/5/5f/Columbia_Lions_logo.svg/1200px-Columbia_Lions_logo.svg.png",
    palette: ["#a1ceec", "#1c3c64", "#82b1d2", "#6c95b9"],
  },
  {
    id: 92,
    call_sign: "KAOS",
    broadcast_frequency: "89.3",
    audio_url: "http://www.golden-apple.com:8930/;",
    station_url: "https://www.kaosradio.org/",
    college_name: "The Evergreen State College",
    public_private: "Public",
    city: "Olympia",
    state: "WA",
    station_image:
      "https://images.squarespace-cdn.com/content/v1/5858568ad1758e3ed9b61481/1516832569469-9P3G8KB1TQ8QCOO1TPIO/b+12+a+reverse+btransparent.png",
    college_image: "https://nwccu.org/wp-content/uploads/2024/01/TESC-Logo.png",
    palette: ["#f9faf9", "#286828", "#85a485", "#7ca47c"],
  },
  {
    id: 93,
    call_sign: "WBGU",
    broadcast_frequency: "88.1",
    audio_url: "http://dvstream2.bgsu.edu:8000/wbgu",
    station_url: "https://www.bgfalconmedia.com/wbgu-fm/",
    college_name: "Bowling Green State University",
    public_private: "Public",
    city: "Bowling Green",
    state: "OH",
    station_image: "https://cdn-radiotime-logos.tunein.com/s27441d.png",
    college_image:
      "https://content.sportslogos.net/logos/30/620/full/bowling_green_falcons_logo_secondary_20043807.png",
    palette: ["#fbfbfa", "#4d2404", "#eb7b3b", "#e0b186"],
  },
  {
    id: 94,
    call_sign: "WMBR",
    broadcast_frequency: "88.1",
    audio_url: "https://wmbr.org:8002/hi",
    station_url: "https://wmbr.org/",
    college_name: "Massachusetts Institute of Technology",
    public_private: "Private",
    city: "Cambridge",
    state: "MA",
    station_image:
      "https://pbs.twimg.com/profile_images/827684532/WMBR_round_400x400.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/MIT_logo.svg/2560px-MIT_logo.svg.png",
    palette: ["#8c8c8c", "#a41c34", "#987490", "#983864"],
  },
  {
    id: 95,
    call_sign: "WRBB",
    broadcast_frequency: "104.9",
    audio_url:
      "https://audio-edge-w4d68.yul.o.radiomast.io/dafd1179-5404-4939-9c1c-a014c6964254",
    station_url: "https://wrbbradio.org/",
    college_name: "Northeastern University",
    public_private: "Private",
    city: "Boston",
    state: "MA",
    station_image: "https://wrbbradio.org/img/logo.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/0/01/Northeastern_Huskies_logo.png",
    palette: ["#cb0404", "#040404", "#cfa4a5", "#5c5c5c"],
  },
  {
    id: 96,
    call_sign: "WITR",
    broadcast_frequency: "89.7",
    audio_url: "https://streaming.witr.rit.edu/high",
    station_url: "https://witr.rit.edu/",
    college_name: "Rochester Institute of Technology",
    public_private: "Private",
    city: "Rochester",
    state: "NY",
    station_image: "https://witr.rit.edu/images/shows/default.png",
    college_image:
      "https://1000logos.net/wp-content/uploads/2019/10/RIT-Tigers-Logo-1976.png",
    palette: ["#f26b1c", "#1d1a1a", "#54545c", "#f4f4f4"],
  },
  {
    id: 97,
    call_sign: "WFMU",
    broadcast_frequency: "91.9",
    audio_url: "https://stream0.wfmu.org/freeform-128k.mp3",
    station_url: "https://wfmu.org/",
    college_name: "Upsala College",
    public_private: "Private",
    city: "East Orange",
    state: "NJ",
    station_image:
      "https://cdn-profiles.tunein.com/s28808/images/logod.jpg?t=1",
    college_image:
      "https://iconape.com/wp-content/files/kg/193341/png/193341.png",
    palette: ["#566b9c", "#d7e9de", "#9eb8c4", "#809dcd"],
  },
  {
    id: 98,
    call_sign: "WMSE",
    broadcast_frequency: "91.7",
    audio_url: "https://wmse.streamguys1.com/wmselivemp3",
    station_url: "https://www.wmse.org/",
    college_name: "Milwaukee School of Engineering",
    public_private: "Private",
    city: "Milwaukee",
    state: "WI",
    station_image:
      "https://www.kexp.org/media/filer_public_thumbnails/filer_public/81/10/8110b8e4-d642-468a-a992-281904e8ba38/wmse.gif__600x600_q85_crop_subsampling-2_upscale.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/Milwaukee_School_of_Engineering_logo.svg/1200px-Milwaukee_School_of_Engineering_logo.svg.png",
    palette: ["#a40c04", "#a80c04", "#a80c04", "#a80c04"],
  },
  {
    id: 99,
    call_sign: "WSUW",
    broadcast_frequency: "91.7",
    audio_url: "http://wsuw-test.uww.edu:80/wsuwliveaac",
    station_url: "https://917theedge.weebly.com/",
    college_name: "University of Wisconsin-Whitewater",
    public_private: "Public",
    city: "Whitewater",
    state: "WI",
    station_image:
      "https://naccchart.com/wp-content/uploads/2019/07/wsuwlogo4.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/8/8f/UW%E2%80%93Whitewater_Warhawks_primary_logo.svg/1200px-UW%E2%80%93Whitewater_Warhawks_primary_logo.svg.png",
    palette: ["#3a2656", "#e7e7e8", "#aba7b6", "#a090c4"],
  },
  {
    id: 100,
    call_sign: "WUSB",
    broadcast_frequency: "90.1",
    audio_url: "https://stream.wusb.stonybrook.edu:8092/listen.pl",
    station_url: "https://www.wusb.fm/",
    college_name: "Stony Brook University",
    public_private: "Public",
    city: "Stony Brook",
    state: "NY",
    station_image:
      "https://alumniandfriends.stonybrook.edu/images/content/pagebuilder/WUSB-resized.jpg",
    college_image:
      "https://www.stonybrook.edu/far-beyond/img/branding/logo/sbu/primary/72/stony-brook-university-logo-vertical.png",
    palette: ["#9c0505", "#040404", "#f4e6e6", "#c47474"],
  },
  {
    id: 35,
    call_sign: "KJHK",
    broadcast_frequency: "90.7",
    audio_url: "https://listen.mixlr.com/349480acbad142f8ea55e3725ae231a1",
    station_url: "https://kjhk.org/",
    college_name: "University of Kansas",
    public_private: "Public",
    city: "Lawrence",
    state: "KS",
    station_image:
      "https://i0.wp.com/kjhk.org/web/wp-content/uploads/2020/10/cropped-BestLogoM04.png?fit=512%2C512&ssl=1",
    college_image:
      "https://content.sportslogos.net/logos/32/718/full/kansas_jayhawks_logo_primary_20059568.png",
    palette: ["#ea9012", "#053ca3", "#f4f6f9", "#5c746c"],
  },
  {
    id: 101,
    call_sign: "CFMU",
    broadcast_frequency: "93.3",
    audio_url: "https://stream.cfmu.ca/mount.128mp3",
    station_url: "https://cfmu.ca/",
    college_name: "McMaster University",
    public_private: "Public",
    city: "Hamilton",
    state: "ON",
    station_image:
      "https://i1.sndcdn.com/avatars-000304478075-sbtthp-t500x500.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/5/53/McMaster_University_logo.svg/1200px-McMaster_University_logo.svg.png",
    palette: ["#4c4055", "#fbc559", "#c87a38", "#9ea4ac"],
  },
  {
    id: 102,
    call_sign: "KDUR",
    broadcast_frequency: "91.9",
    audio_url: "http://kdurradio.fortlewis.edu/stream",
    station_url: "https://www.kdur.org/",
    college_name: "Fort Lewis College",
    public_private: "Public",
    city: "Durango",
    state: "CO",
    station_image:
      "https://www.kdur.org/Portals/12/skins/kdur/FileUploads/12/kdur-radio-logo-2_3.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/e/e9/Fort_Lewis_Skyhawks_logo.svg/1200px-Fort_Lewis_Skyhawks_logo.svg.png",
    palette: ["#054c8b", "#fabb34", "#fafafb", "#047cc4"],
  },
  {
    id: 103,
    call_sign: "WTHS",
    broadcast_frequency: "89.9",
    audio_url: "https://s3.radio.co/scf79220ed/listen",
    station_url: "https://wths.hope.edu/",
    college_name: "Hope College",
    public_private: "Private",
    city: "Holland",
    state: "MI",
    station_image:
      "https://cdn-profiles.tunein.com/s23126/images/logod.png?t=156139WT",
    college_image:
      "https://hope.edu/offices/public-affairs-marketing/resources/logos/athletics/block-h-spirit/HOPE_Block-H_Spirit-Mark_OrangeBlue.png",
    palette: ["#f37b24", "#042c5c", "#a86345", "#685254"],
  },
  {
    id: 104,
    call_sign: "UWSR",
    broadcast_frequency: "87.7",
    audio_url: "http://icecast.commedia.org.uk:8000/ucaradio.mp3",
    station_url: "http://uwsradio.co.uk/",
    college_name: "University of West Scotland",
    public_private: "Public",
    city: "Ayr",
    state: "Scotland",
    station_image:
      "https://i0.wp.com/uwsradio.co.uk/wp-content/uploads/2021/09/UWS-pink-scaled.jpg?fit=370%2C370",
    college_image:
      "https://www.studyabroad.pk/images/companyLogo/UWS%20logo.png",
    palette: ["#f24f38", "#fbe8e5", "#f4a49c", "#80291f"],
  },
  {
    id: 105,
    call_sign: "RUA",
    broadcast_frequency: "103.7",
    audio_url: "https://centova.radio.com.pt/proxy/536?mp=/stream",
    station_url: "https://rua.pt/",
    college_name: "University of Algarve",
    public_private: "Public",
    city: "Faro",
    state: "Portugal",
    station_image:
      "https://rua.pt/wp-content/uploads/2021/10/cropped-favicon.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/b/bd/Uoalogo.png",
    palette: ["#0794e2", "#1e1e16", "#aacddc", "#7e7c79"],
  },
  {
    id: 106,
    call_sign: "UFM",
    broadcast_frequency: "104.3",
    audio_url: "https://centova.radio.com.pt/proxy/405?mp=/stream",
    station_url: "https://www.universidade.fm/",
    college_name: "University of Trás-os-Montes and Alto Douro",
    public_private: "Public",
    city: "Vila Real",
    state: "Portugal",
    station_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8t5ilOWOBPQTdvD9iw1s5MvuFTXLwf3wVEA&s",
    college_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ20HtiBs-uHVPu6KEV7KjUhwdPnDD9QzGAbaJ-orlKnQ&s",
    palette: ["#052c82", "#8d0505", "#840404", "#7c6a8a"],
  },
  {
    id: 107,
    call_sign: "UAL",
    broadcast_frequency: "91.7",
    audio_url: "https://spaudio.servers.pt/8006/stream",
    station_url: "https://ualmedia.pt/",
    college_name: "Autonomous University of Lisbon",
    public_private: "Private",
    city: "Lisbon",
    state: "Portugal",
    station_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR47-9fQAsFJut7nUexgmcaopEJ-IoJA63xuA&s",
    college_image:
      "https://autonoma.pt/wp-content/uploads/2018/01/logoUAL1.png",
    palette: ["#0474a4", "#050505", "#3697b9", "#5c5c5c"],
  },
  {
    id: 109,
    call_sign: "WCFM",
    broadcast_frequency: "91.9",
    audio_url: "http://137.165.206.193:8000/stream?",
    station_url: "https://sites.williams.edu/wcfm/",
    college_name: "Williams College",
    public_private: "Private",
    city: "Williamstown",
    state: "MA",
    station_image: "https://cdn-radiotime-logos.tunein.com/s27821g.png",
    college_image: "https://ephsports.williams.edu/images/logos/site/site.png",
    palette: ["#f7bf30", "#560782", "#b76e39", "#9464b4"],
  },
  {
    id: 110,
    call_sign: "XEUN",
    broadcast_frequency: "96.1",
    audio_url: "https://tv.radiohosting.online:9484/stream",
    station_url: "https://www.radio.unam.mx/",
    college_name: "National Autonomous University of Mexico",
    public_private: "Public",
    city: "Mexico City",
    state: "Mexico",
    station_image: "https://cdn-radiotime-logos.tunein.com/s24539d.png",
    college_image:
      "http://www.sorbonne-universite.fr/sites/default/files/styles/345xauto/public/media/2021-03/UNAM-logo-272x300.png?itok=YafUZxky",
    palette: ["#eaae0d", "#36380c", "#0581d9", "#4ba60e"],
  },
  {
    id: 111,
    call_sign: "CAMF",
    broadcast_frequency: "97.2",
    audio_url: "https://stream.camfm.co.uk/camfm",
    station_url: "https://www.camfm.co.uk/",
    college_name: "University of Cambridge",
    public_private: "Public",
    city: "Cambridge",
    state: "United Kingdom",
    station_image:
      "https://www.radio.net/images/broadcasts/a4/9f/16997/c300.png",
    college_image:
      "https://callforcurators.com/wp-content/uploads/2022/01/university-of-cambridge-logo-3.png",
    palette: ["#17150c", "#e8671f", "#fbfbfb", "#8c8c8c"],
  },
  {
    id: 112,
    call_sign: "OXID",
    broadcast_frequency: "87.9",
    audio_url: "https://uk3.internet-radio.com/proxy/oxideradio?mp=/stream",
    station_url: "https://www.oxideradio.live/",
    college_name: "University of Oxford",
    public_private: "Public",
    city: "Oxford",
    state: "United Kingdom",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/2/2e/Oxide_2005_logo.jpg/220px-Oxide_2005_logo.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Oxford-University-Circlet.svg/1636px-Oxford-University-Circlet.svg.png",
    palette: ["#0c223f", "#dab574", "#8494a4", "#848ca4"],
  },
  {
    id: 113,
    call_sign: "LR11",
    broadcast_frequency: "107.5",
    audio_url: "https://stream.radiouniversidad.unlp.edu.ar:8040/fm",
    station_url: "https://www.radiouniversidad.unlp.edu.ar/",
    college_name: "National University of La Plata",
    public_private: "Public",
    city: "La Plata",
    state: "Argentina",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/d/d9/Logo_Radio_Universidad.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/UNLP_Logo_%28cropped%29.svg/1200px-UNLP_Logo_%28cropped%29.svg.png",
    palette: ["#f9f9f9", "#1d1515", "#757474", "#7d7c7c"],
  },
  {
    id: 114,
    call_sign: "WKOW",
    broadcast_frequency: "91.3",
    audio_url: "https://radioradieschen.stream.kapper.net/radioradieschen",
    station_url: "https://www.radio-radieschen.at/",
    college_name: "University of Applied Sciences Vienna",
    public_private: "Public",
    city: "Vienna",
    state: "Austria",
    station_image:
      "https://cdn-profiles.tunein.com/s153013/images/logod.jpg?t=161642",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/9/9a/FH_Technikum_Wien_logo.svg",
    palette: ["#d6d5cf", "#040404", "#f48cb4", "#40807c"],
  },
  {
    id: 115,
    call_sign: "WRCP",
    broadcast_frequency: "93.9",
    audio_url: "https://www.radiocampusparis.org/stream/",
    station_url: "https://www.radiocampusparis.org/",
    college_name: "University of Paris",
    public_private: "Public",
    city: "Paris",
    state: "France",
    station_image:
      "https://www.radio.net/images/broadcasts/08/e2/29192/c175.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Coat_of_arms_of_the_University_of_Paris.svg/640px-Coat_of_arms_of_the_University_of_Paris.svg.png",
    palette: ["#f3d09e", "#436ce8", "#2b2925", "#acaca0"],
  },
  {
    id: 116,
    call_sign: "WRCT",
    broadcast_frequency: "88.3",
    audio_url: "http://stream.wrct.org:8000/wrct-hi.mp3",
    station_url: "http://www.wrct.org/",
    college_name: "Carnegie Mellon University",
    public_private: "Private",
    city: "Pittsburgh",
    state: "PA",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/4/4f/WRCT_88.3_Logo.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/b/bb/Carnegie_Mellon_University_seal.svg/1200px-Carnegie_Mellon_University_seal.svg.png",
    palette: ["#243647", "#e6a21a", "#fbfbf9", "#8b3142"],
  },
  {
    id: 117,
    call_sign: "WSBU",
    broadcast_frequency: "88.3",
    audio_url: "https://19293.live.streamtheworld.com/WSBUFMAAC.aac",
    station_url: "https://wsbufm.com/",
    college_name: "St. Bonaventure University",
    public_private: "Private",
    city: "St. Bonaventure",
    state: "NY",
    station_image:
      "https://pbs.twimg.com/profile_images/1488244601135763456/-w1qGgD-_400x400.jpg",
    college_image:
      "https://i.pinimg.com/originals/8e/70/bf/8e70bf12074aa24e5e32688b7a4aa56c.gif",
    palette: ["#651d0d", "#e6e1e0", "#f2d282", "#b48c8c"],
  },
  {
    id: 118,
    call_sign: "KNTU",
    broadcast_frequency: "88.1",
    audio_url: "https://ice41.securenetsystems.net/KNTU",
    station_url: "https://kntu.com/",
    college_name: "University of North Texas",
    public_private: "Public",
    city: "Denton",
    state: "TX",
    station_image: "https://cdn-radiotime-logos.tunein.com/s34379g.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/a/a2/North_Texas_Mean_Green_logo.svg/1200px-North_Texas_Mean_Green_logo.svg.png",
  },
  {
    id: 119,
    call_sign: "KSJS",
    broadcast_frequency: "90.5",
    audio_url: "https://streaming.ksjs.sjsu.edu/",
    station_url: "https://ksjs.org/",
    college_name: "San Jose State University",
    public_private: "Public",
    city: "San Jose",
    state: "CA",
    station_image:
      "https://spinitron.com/images/Station/13/1338-img_logo.225x225.jpg?v=1687376816",
    college_image:
      "https://content.sportslogos.net/logos/34/828/full/san_jose_state_spartans_logo_primary_2018_sportslogosnet-7512.png",
  },
  {
    id: 120,
    call_sign: "KCSN",
    broadcast_frequency: "88.5",
    audio_url: "https://stream1.thesocalsound.org/1",
    station_url: "https://www.thesocalsound.org/",
    college_name: "California State University, Northridge",
    public_private: "Public",
    city: "Northridge",
    state: "CA",
    station_image:
      "https://radioink.com/wp-content/uploads/2022/08/KCSN-SoCal-Sound-2022.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/8/8c/CSUNS.svg/1200px-CSUNS.svg.png",
  },
  {
    id: 121,
    call_sign: "WRVU",
    broadcast_frequency: "90.3",
    audio_url: "https://streaming.wrvu.org/live",
    station_url: "https://wrvu.org/",
    college_name: "Vanderbilt University",
    public_private: "Private",
    city: "Nashville",
    state: "TN",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/1/18/WRVU_logo.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/Vanderbilt_Commodores_logo.svg/640px-Vanderbilt_Commodores_logo.svg.png",
  },
  {
    id: 122,
    call_sign: "KUCI",
    broadcast_frequency: "88.9",
    audio_url: "https://streamer.kuci.org:8088/high",
    station_url: "https://kuci.org/wp/",
    college_name: "University of California, Irvine",
    public_private: "Public",
    city: "Irvine",
    state: "CA",
    station_image:
      "https://kuci.org/wp/wp-content/uploads/2018/06/KUCIheaderlogo2.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/UC_Irvine_Anteaters_logo.svg/1200px-UC_Irvine_Anteaters_logo.svg.png",
  },
  {
    id: 123,
    call_sign: "WKSU",
    broadcast_frequency: "89.7",
    audio_url: "https://live.ideastream.org/wksu1.128.mp3",
    station_url: "https://www.wksu.org/",
    college_name: "Kent State University",
    public_private: "Public",
    city: "Kent",
    state: "Ohio",
    station_image:
      "https://cdn-profiles.tunein.com/s30407/images/logog.png?t=1679481797000",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/f/f3/Kent_State_K_logo.png",
  },
  {
    id: 124,
    call_sign: "KVCU",
    broadcast_frequency: "1190",
    audio_url: "https://kvcu.streamguys1.com/live",
    station_url: "https://1190.radio/",
    college_name: "University of Colorado at Boulder",
    public_private: "Public",
    city: "Boulder",
    state: "CO",
    station_image:
      "https://1190.radio/wp-content/uploads/2023/05/1190-logo-black.png",
    college_image: "https://www.cu.edu/sites/default/files/cu-standalone.png",
  },
  {
    id: 125,
    call_sign: "WEGL",
    broadcast_frequency: "91.1",
    audio_url: "https://ais-sa1.streamon.fm/7116_32k.aac",
    station_url: "https://www.weglfm.com/",
    college_name: "Auburn University",
    public_private: "Public",
    city: "Auburn",
    state: "AL",
    station_image:
      "https://se-images.campuslabs.com/clink/images/5c4ffc55-182e-4719-aa77-c3f18201d731e6239f76-3592-4397-80cb-bf6e158b52f7.png?preset=med-sq",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Auburn_Tigers_logo.svg/1200px-Auburn_Tigers_logo.svg.png",
  },
  {
    id: 126,
    call_sign: "KCSB",
    broadcast_frequency: "91.9",
    audio_url: "http://live.kcsb.org/KCSB_128",
    station_url: "https://www.kcsb.org/",
    college_name: "University of California, Santa Barbara",
    public_private: "Public",
    city: "Santa Barbara",
    state: "CA",
    station_image:
      "https://www.kcsb.org/wp-content/themes/kcsb2019/images/KCSB--logo.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/a/a8/UC_Santa_Barbara_Gauchos_logo.svg/1200px-UC_Santa_Barbara_Gauchos_logo.svg.png",
  },
  {
    id: 127,
    call_sign: "WNUR",
    broadcast_frequency: "89.3",
    audio_url: "https://n15.rcs.revma.com/w4pmmfkdx4zuv",
    station_url: "https://wnur.northwestern.edu/",
    college_name: "Northwestern University",
    public_private: "Private",
    city: "Evanston",
    state: "IL",
    station_image:
      "https://res.cloudinary.com/scalefunder/image/upload/c_crop,h_3600,w_6360,x_820,y_450/c_scale,h_343,w_606/f_auto,fl_lossy,q_auto/v1/Northwestern_University/enzxjboi4yuungagc4v5",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/Northwestern_Wildcats_logo.svg/1330px-Northwestern_Wildcats_logo.svg.png",
  },
  {
    id: 128,
    call_sign: "WHPK",
    broadcast_frequency: "88.5",
    audio_url: "http://205.208.30.113:8000/stream",
    station_url: "https://whpk.org/",
    college_name: "University of Chicago",
    public_private: "Private",
    city: "Chicago",
    state: "IL",
    station_image:
      "https://cdn-profiles.tunein.com/s29405/images/logog.jpg?t=1",
    college_image:
      "https://i0.wp.com/biocars.uchicago.edu/wp-content/uploads/2019/05/logo.png?resize=1200%2C1200&ssl=1&w=640",
  },
  {
    id: 129,
    call_sign: "WHRW",
    broadcast_frequency: "90.5",
    audio_url: "https://securestreams4.autopo.st:1794/hi",
    station_url: "https://whrw.fm/",
    college_name: "State University of New York at Binghamton",
    public_private: "Public",
    city: "Binghamton",
    state: "NY",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/0/02/WHRW_logo.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/d/d8/Binghamton_Bearcats_logo.svg/1200px-Binghamton_Bearcats_logo.svg.png",
  },
  {
    id: 130,
    call_sign: "WUAG",
    broadcast_frequency: "103.1",
    audio_url: "https://das-edge09-live365-dal03.cdnstream.com/a43900",
    station_url: "https://wuag.uncg.edu/",
    college_name: "University of North Carolina at Greensboro",
    public_private: "Public",
    city: "Greensboro",
    state: "NC",
    station_image:
      "https://pbs.twimg.com/profile_images/1545153062/Wuag_Logo_on_Square_400x400.jpg",
    college_image:
      "https://150832185.v2.pressablecdn.com/wp-content/uploads/2023/03/uncg_emblem_3-color-e1680293422193.png",
  },
  {
    id: 131,
    call_sign: "WIDR",
    broadcast_frequency: "89.1",
    audio_url: "http://widrfm.net/stream",
    station_url: "https://www.widrfm.org/",
    college_name: "Western Michigan University",
    public_private: "Public",
    city: "Kalamazoo",
    state: "MI",
    station_image:
      "https://cdn-profiles.tunein.com/s29530/images/logog.png?t=1723711379000",
    college_image:
      "https://wmich.edu/sites/default/files/attachments/u171/2020/WMU%20stacked-digital.png",
  },
  {
    id: 132,
    call_sign: "WLUW",
    broadcast_frequency: "88.7 FM",
    audio_url: "https://wluw.streamguys1.com/stream.mp3",
    station_url: "https://wluw.org/",
    college_name: "Loyola University of Chicago",
    public_private: "Private",
    city: "Chicago",
    state: "IL",
    station_image:
      "https://dqa4a6x5zonsi.cloudfront.net/img/stations/wluw/icons/android-chrome-384x384.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/6/69/Loyola_Ramblers_logo.svg/1200px-Loyola_Ramblers_logo.svg.png",
  },
  {
    id: 133,
    call_sign: "KGLT",
    broadcast_frequency: "91.9",
    audio_url: "https://live.kgltradio.com/256",
    station_url: "https://kglt.net/",
    college_name: "Montana State University",
    public_private: "Public",
    city: "Bozeman",
    state: "MT",
    station_image:
      "https://kglt.net/wp-content/themes/kglt-2020/images/kglt-retro-trans-sq-240.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/2/2f/Montana_State_Bobcats_logo.svg/1200px-Montana_State_Bobcats_logo.svg.png",
  },
  {
    id: 134,
    call_sign: "WSND",
    broadcast_frequency: "88.9",
    audio_url: "https://streamer.radio.co/s9751a0c22/listen",
    station_url: "https://wsnd.nd.edu/",
    college_name: "University of Notre Dame",
    public_private: "Private",
    city: "Notre Dame",
    state: "IN",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/9/9a/09wsndlogo.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/Notre_Dame_Fighting_Irish_logo.svg/2276px-Notre_Dame_Fighting_Irish_logo.svg.png",
  },
  {
    id: 135,
    call_sign: "WCSB",
    broadcast_frequency: "89.3",
    audio_url: "https://or.mysonicserver.com/8030/stream",
    station_url: "https://www.wcsb.org/",
    college_name: "Cleveland State University",
    public_private: "Public",
    city: "Cleveland",
    state: "OH",
    station_image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/Gp7Xg6_aRyUE9AwTIc5SmA/l.jpg",
    college_image:
      "https://dbukjj6eu5tsf.cloudfront.net/sidearm.sites/clevelandst.sidearmsports.com/images/responsive_2024/logo_main.png",
  },
  {
    id: 136,
    call_sign: "KUOI",
    broadcast_frequency: "89.3 FM",
    audio_url: "https://s2.radio.co/sedf30688d/listen",
    station_url: "https://www.kuoi.org/",
    college_name: "University of Idaho",
    public_private: "Public",
    city: "Moscow",
    state: "ID",
    station_image: "https://www.kuoi.org/header.svg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/Idaho_Vandals_logo.svg/1200px-Idaho_Vandals_logo.svg.png",
  },
  {
    id: 137,
    call_sign: "KWTS",
    broadcast_frequency: "91.1",
    audio_url: "https://ice41.securenetsystems.net/KWTS",
    station_url:
      "https://www.wtamu.edu/academics/college-fine-arts-humanities/department-communication/get-involved/kwts.html",
    college_name: "West Texas A&M University",
    public_private: "Public",
    city: "Canyon",
    state: "TX",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/5/5f/Kwts-fm-logo.png",
    college_image:
      "https://www.wtamu.edu/webres/Image/Site%20Pictures/News/graphic_standards_2015fall/SpiritWT_7421.png",
  },
  // Seton Hall has a special setup where encode snippets of the stream in XML requests instead a continuous streaming link
  // I think I could make it work but it would require writing a special player
  // {
  //   id: 138,
  //   call_sign: "WSOU",
  //   broadcast_frequency: "89.5",
  //   audio_url: "https://d3byg0ij92yqk6.cloudfront.net/streamWSOU1653065491.aac",
  //   station_url: "https://www.wsou.net/",
  //   college_name: "Seton Hall University",
  //   public_private: "Private",
  //   city: "South Orange",
  //   state: "NJ",
  //   station_image:
  //     "https://upload.wikimedia.org/wikipedia/commons/5/52/WSOU_New_Decal_Design.jpg",
  //   college_image:
  //     "https://content.sportslogos.net/logos/34/831/full/seton_hall_pirates_logo_secondary_19983416.png",
  //   palette: ["#0464ac", "#f9fafa", "#acb4b4", "#7fb0d3"],
  // },
  {
    id: 139,
    call_sign: "WNCU",
    broadcast_frequency: "90.7",
    audio_url: "https://wncu.streamguys1.com/live",
    station_url: "https://www.wncu.org/",
    college_name: "North Carolina Central University",
    public_private: "Public",
    city: "Durham",
    state: "NC",
    station_image:
      "https://player.streamguys.com/wncu/sgplayer/include/assets/img/wncu_logo.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/1/13/NCCU_Eagles_head_mark_logo.svg/1200px-NCCU_Eagles_head_mark_logo.svg.png",
  },
  {
    id: 140,
    call_sign: "WIXQ",
    broadcast_frequency: "91.7",
    audio_url: "http://wixq.millersville.edu:8000/live.nsv",
    station_url: "https://www.917theville.com/",
    college_name: "Millersville University of Pennsylvania",
    public_private: "Public",
    city: "Millersville",
    state: "PA",
    station_image:
      "https://static.wixstatic.com/media/23bab1_de7bc9bb6d7a49c8b631e5e29e151a98.png/v1/fill/w_352,h_192,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/WIXQOFFICIALLOGO2015.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/6/61/Millersville_Marauders_logo.svg",
  },
  {
    id: 141,
    call_sign: "WCWM",
    broadcast_frequency: "90.9",
    audio_url: "https://wcwm.broadcasttool.stream/wcwm-onair",
    station_url: "https://wcwm.wm.edu/",
    college_name: "College of William and Mary",
    public_private: "Public",
    city: "Williamsburg",
    state: "VA",
    station_image:
      "https://wcwm.wm.edu/wp-content/uploads/2023/12/WCWM_SOCIAL_MAIN.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/William_%26_Mary_Athletics_logo.svg/1200px-William_%26_Mary_Athletics_logo.svg.png",
  },
  {
    id: 142,
    call_sign: "KTRM",
    broadcast_frequency: "88.7",
    audio_url: "https://securestream.truman.edu/ktrm",
    station_url: "https://tmn.truman.edu/",
    college_name: "Truman State University",
    public_private: "Public",
    city: "Kirksville",
    state: "MO",
    station_image:
      "https://tmn.truman.edu/wp-content/uploads/2024/10/KTRM-Logo-Final_2024.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/f/fc/Truman_Bulldogs_logo.svg/1200px-Truman_Bulldogs_logo.svg.png",
  },
  {
    id: 143,
    call_sign: "WVUA",
    broadcast_frequency: "90.7",
    audio_url: "https://das-edge17-live365-dal02.cdnstream.com/a83139",
    station_url: "http://wvuafm.ua.edu/",
    college_name: "University of Alabama",
    public_private: "Public",
    city: "Tuscaloosa",
    state: "AL",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/WVUA_logo.png/640px-WVUA_logo.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Alabama_Athletics_logo.svg/1200px-Alabama_Athletics_logo.svg.png",
  },
  {
    id: 144,
    call_sign: "KTXT",
    broadcast_frequency: "88.1",
    audio_url: "https://ice7.securenetsystems.net/KTXTFM",
    station_url: "https://www.ktxtfm.org/raider/",
    college_name: "Texas Tech University",
    public_private: "Public",
    city: "Lubbock",
    state: "Texas",
    station_image:
      "https://www.ktxtfm.org/raider/wp-content/uploads/2024/11/Triangle-Logo-v2.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Texas_Tech_Athletics_logo.svg/1200px-Texas_Tech_Athletics_logo.svg.png",
  },
  {
    id: 145,
    call_sign: "WUSC",
    broadcast_frequency: "90.5",
    audio_url: "https://stream.wusc.fm/listen.mp3",
    station_url: "https://www.wusc.fm/",
    college_name: "University of South Carolina",
    public_private: "Public",
    city: "Columbia",
    state: "SC",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/d/d4/WUSC_90.5FM_logo.png",
    college_image:
      "https://sc.edu/about/offices_and_divisions/communications/images/toolbox/logos/grid_athletics_logo.png",
  },
  {
    id: 146,
    call_sign: "WMUA",
    broadcast_frequency: "91.1",
    audio_url: "https://usa5.fastcast4u.com/proxy/qernhlca?mp=/1",
    station_url: "https://www.umass.edu/wmua/",
    college_name: "University of Massachusetts at Amherst",
    public_private: "Public",
    city: "Amherst",
    state: "MA",
    station_image:
      "https://www.massbroadcasters.org/wp-content/uploads/2013/05/WMUA.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/UMass_Amherst_athletics_logo.svg/1200px-UMass_Amherst_athletics_logo.svg.png",
  },

  {
    id: 147,
    call_sign: "KGOU",
    broadcast_frequency: "106.3",
    audio_url: "https://18123.live.streamtheworld.com/KGOUFM_64.mp3",
    station_url: "https://www.kgou.org/",
    college_name: "University of Oklahoma",
    public_private: "Public",
    city: "Norman",
    state: "OK",
    station_image:
      "https://npr.brightspotcdn.com/dims4/default/9274310/2147483647/strip/true/crop/1905x777+0+0/resize/880x359!/quality/90/?url=http%3A%2F%2Fnpr-brightspot.s3.amazonaws.com%2F03%2Fc1%2F27c0d13b4f648f157b84c51bb2a4%2Fbluelogo-closecrop.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/Oklahoma_Sooners_logo.svg/1589px-Oklahoma_Sooners_logo.svg.png",
  },
  {
    id: 148,
    call_sign: "KBLU",
    broadcast_frequency: "92.3",
    audio_url: "https://aggieradio.creek.stream/stream",
    station_url: "https://www.usu.edu/student-media/aggie-radio/",
    college_name: "Utah State University",
    public_private: "Public",
    city: "Logan",
    state: "UT",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/6/62/92.3_KBLU-LP_Logan.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Utah_State_Aggies_logo.svg/1200px-Utah_State_Aggies_logo.svg.png",
  },
  {
    id: 149,
    call_sign: "KJACK",
    broadcast_frequency: "107.1",
    audio_url: "https://stream.ec.nau.edu:8443/kjack",
    station_url: "https://www.kjackradio.com/",
    college_name: "Northern Arizona University",
    public_private: "Public",
    city: "Flagstaff",
    state: "AZ",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/e/ed/KJACK.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Northern_Arizona_Athletics_logo.svg/1200px-Northern_Arizona_Athletics_logo.svg.png",
  },
  {
    id: 150,
    call_sign: "WUMS",
    broadcast_frequency: "92.1",
    audio_url: "http://130.74.34.21:8000/listen",
    station_url: "https://myrebelradio.com/",
    college_name: "University of Mississippi",
    public_private: "Public",
    city: "University",
    state: "MS",
    station_image:
      "https://myrebelradio.com/wp-content/uploads/2023/01/Rebel-Radio-Redo-headphones-spotify.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/7/7c/Ole-miss_logo_from_NCAA.svg",
  },
  {
    id: 151,
    call_sign: "WZMB",
    broadcast_frequency: "91.3",
    audio_url: "https://ice41.securenetsystems.net/WZMB",
    station_url: "https://www.piratemedia1.com/wzmb913fm/",
    college_name: "East Carolina University",
    public_private: "Public",
    city: "Greenville",
    state: "NC",
    station_image:
      "https://studentmedia.ecu.edu/wp-content/pv-uploads/sites/166/2019/06/WZMB_NewLogo_BlackBackground.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/c/c7/East_Carolina_Pirates_logo.svg/1200px-East_Carolina_Pirates_logo.svg.png",
  },
  {
    id: 152,
    call_sign: "KTEP",
    broadcast_frequency: "88.5",
    audio_url: "https://25023.live.streamtheworld.com/KTEPFM_ADP.aac",
    station_url: "https://www.ktep.org/",
    college_name: "University of Texas at El Paso",
    public_private: "Public",
    city: "El Paso",
    state: "TX",
    station_image:
      "https://www.theprospectordaily.com/wp-content/uploads/2020/11/110220_KTEP-900x493.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/0/06/UTEP_Miners_logo.svg/1200px-UTEP_Miners_logo.svg.png",
  },
  {
    id: 153,
    call_sign: "WEMU",
    broadcast_frequency: "89.1",
    audio_url: "https://26273.live.streamtheworld.com/WEMUFMAAC.aac",
    station_url: "https://www.wemu.org/",
    college_name: "Eastern Michigan University",
    public_private: "Public",
    city: "Ypsilanti",
    state: "MI",
    station_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRapZ2PiX6TOzzfwppHCZshUGj2-T2Nc2BRmQ&s",
    college_image:
      "https://1000logos.net/wp-content/uploads/2019/12/Eastern-Michigan-Eagles-Logo-2003.png",
  },
  {
    id: 154,
    call_sign: "WMSV",
    broadcast_frequency: "91.1",
    audio_url: "https://stream.wmsv.msstate.edu:8000/wmsv",
    station_url: "https://www.wmsv.msstate.edu/",
    college_name: "Mississippi State University",
    public_private: "Public",
    city: "Mississippi State",
    state: "MS",
    station_image:
      "https://upload.wikimedia.org/wikipedia/commons/b/b3/WMSV_Logo.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Mississippi_State_Bulldogs_logo.svg/1280px-Mississippi_State_Bulldogs_logo.svg.png",
  },
  {
    id: 155,
    call_sign: "KUCR",
    broadcast_frequency: "88.3",
    audio_url: "http://138.23.75.92:8001/",
    station_url: "https://kucr.org/",
    college_name: "University of California, Riverside",
    public_private: "Public",
    city: "Riverside",
    state: "CA",
    station_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrR87gi-z7kcEQ4415lDh0FnNgYHNB71aouw&s",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/2/21/UC_Riverside_Highlanders_logo.svg",
  },
  {
    id: 156,
    call_sign: "WZIP",
    broadcast_frequency: "88.1 FM",
    audio_url: "https://www.streamvortex.com:8444/s/11300",
    station_url: "https://wzip.uakron.edu/",
    college_name: "University of Akron",
    public_private: "Public",
    city: "Akron",
    state: "OH",
    station_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS_cjl2PFlq_i_URlyTRLtczLoVdSBmsxUkg&s",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Akron_Zips_logo_2022.svg/1200px-Akron_Zips_logo_2022.svg.png",
  },
  {
    id: 157,
    call_sign: "KUNV",
    broadcast_frequency: "91.5",
    audio_url: "http://kunv.oit.unlv.edu:8000/stream/4/",
    station_url: "https://www.unlv.edu/kunv",
    college_name: "University of Nevada, Las Vegas",
    public_private: "Public",
    city: "Las Vegas",
    state: "NV",
    station_image:
      "https://www.unlv.edu/sites/default/files/styles/1140_width/public/media/image/2023-05/Public-Radio-KUNV-Main-Transparent.png?itok=zMA5syH5",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/UNLV_Rebels_wordmark.svg/1200px-UNLV_Rebels_wordmark.svg.png",
  },
  {
    id: 158,
    call_sign: "WZND",
    broadcast_frequency: "103.3",
    audio_url: "https://26353.live.streamtheworld.com/WZNDFM.mp3",
    station_url: "https://wznd.com/",
    college_name: "Illinois State University",
    public_private: "Public",
    city: "Normal",
    state: "IL",
    station_image:
      "https://wznd.com/wp-content/uploads/2018/12/Wznd-Logo-2.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/f/f8/Illinois_State_Athletics_logo.svg/1200px-Illinois_State_Athletics_logo.svg.png",
  },
  {
    id: 159,
    call_sign: "WFDD",
    broadcast_frequency: "88.5",
    audio_url: "http://152.17.49.84:8000/wakeradio",
    station_url: "https://wakeradio.groups.wfu.edu/",
    college_name: "Wake Forest University",
    public_private: "Private",
    city: "Winston-Salem",
    state: "NC",
    station_image:
      "https://images.squarespace-cdn.com/content/v1/5bc0ef807d0c9158b1ea3f68/1540518126677-O34UXS4U8A4126A87P0I/logo.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Wake_Forest_University_Athletic_logo.svg/2560px-Wake_Forest_University_Athletic_logo.svg.png",
  },
  {
    id: 160,
    call_sign: "KWBU",
    broadcast_frequency: "103.3",
    audio_url: "https://radio.clevr.me/kwbu",
    station_url: "https://www.kwbu.org/",
    college_name: "Baylor University",
    public_private: "Private",
    city: "Waco",
    state: "TX",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/9/96/KWBU_logo.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/b/b0/2018_Baylor_Athletics_Logo.png",
  },
  {
    id: 161,
    call_sign: "KSDB",
    broadcast_frequency: "91.9",
    audio_url: "https://s.radiowave.io/ksdb.mp3",
    station_url: "https://wildcat919.com/",
    college_name: "Kansas State University",
    public_private: "Public",
    city: "Manhattan",
    state: "KS",
    station_image:
      "https://static.wixstatic.com/media/76919c_f4b20d9622e94c12b90ef59cc2705620~mv2.png/v1/fit/w_2500,h_1330,al_c/76919c_f4b20d9622e94c12b90ef59cc2705620~mv2.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/e/e5/Kansas_State_Wildcats_logo.svg/1200px-Kansas_State_Wildcats_logo.svg.png",
  },
  {
    id: 162,
    call_sign: "WYXR",
    broadcast_frequency: "91.7",
    audio_url: "https://crosstown.streamguys1.com/live-website-aac",
    station_url: "https://wyxr.org/",
    college_name: "University of Memphis",
    public_private: "Public",
    city: "Memphis",
    state: "TN",
    station_image:
      "https://spinitron.com/images/Station/19/1911-img_logo.225x225.png?v=1599495201",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/4/45/Memphis_Tigers_logo.svg/1200px-Memphis_Tigers_logo.svg.png",
  },
  {
    id: 163,
    call_sign: "KASU",
    broadcast_frequency: "91.9",
    audio_url: "https://kasu.streamguys1.com/live.mp3",
    station_url: "https://www.kasu.org/",
    college_name: "Arkansas State University",
    public_private: "Public",
    city: "Jonesboro",
    state: "AR",
    station_image:
      "https://npr.brightspotcdn.com/dims4/default/6956741/2147483647/strip/true/crop/900x413+0+0/resize/880x404!/quality/90/?url=http%3A%2F%2Fnpr-brightspot.s3.amazonaws.com%2Fd7%2F4a%2Ff54801fc4a918c3314582d4e2c62%2Fkasu-logo.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Arkansas_State_Red_Wolves_wordmark.svg/1200px-Arkansas_State_Red_Wolves_wordmark.svg.png",
  },
  {
    id: 164,
    call_sign: "KWUR",
    broadcast_frequency: "90.3",
    audio_url: "https://listen.mixlr.com/25e83e0436e36c0e95b3db8f8826c1af",
    station_url: "http://kwur.wustl.edu/",
    college_name: "Washington University in St. Louis",
    public_private: "Private",
    city: "St. Louis",
    state: "MO",
    station_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4rqUpn0F9VVLMm7ChzcpwPab4YK5mwfHjIQ&s",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/a/ae/Washington_University_Bears_primary_athletic_logo.png",
  },
  {
    id: 165,
    call_sign: "WHBC",
    broadcast_frequency: "96.3",
    audio_url: "https://ais-sa1.streamon.fm/7165_48k.aac",
    station_url: "https://www.whbc963hd3.com/",
    college_name: "Howard University",
    public_private: "Private",
    city: "Washington",
    state: "DC",
    station_image: "https://logo.clearbit.com/www.whbc963hd3.com",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/b/b4/Howard_Bison_logo.svg/1200px-Howard_Bison_logo.svg.png",
  },
  {
    id: 166,
    call_sign: "KUWR",
    broadcast_frequency: "91.9",
    audio_url: "https://wyoming-public-ice.streamguys1.com/WPR128MP3",
    station_url: "https://www.wyomingpublicmedia.org/",
    college_name: "University of Wyoming",
    public_private: "Public",
    city: "Laramie",
    state: "WY",
    station_image:
      "https://cdn-profiles.tunein.com/s35981/images/logog.png?t=1",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Wyoming_Athletics_logo.svg/1200px-Wyoming_Athletics_logo.svg.png",
  },
  {
    id: 167,
    call_sign: "WUDR",
    broadcast_frequency: "99.5",
    audio_url: "https://ais-sa1.streamon.fm/7041_48k.aac",
    station_url:
      "https://udayton.edu/studev/leadership/involvement/student-life/org-radio.php",
    college_name: "University of Dayton",
    public_private: "Private",
    city: "Dayton",
    state: "OH",
    station_image: "https://upload.wikimedia.org/wikipedia/en/6/60/Side.gif",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Dayton_Flyers_logo.svg/1200px-Dayton_Flyers_logo.svg.png",
  },
  {
    id: 168,
    call_sign: "WXVU",
    broadcast_frequency: "89.1",
    audio_url: "https://securestream.casthost.net:8159/stream",
    station_url: "https://wxvu.org/",
    college_name: "Villanova University",
    public_private: "Private",
    city: "Villanova",
    state: "PA",
    station_image:
      "https://wxvu.org/wp-content/uploads/2022/01/WXVU-The-Roar-RadioFX.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/Villanova_Wildcats_logo.svg/1200px-Villanova_Wildcats_logo.svg.png",
  },
  {
    id: 169,
    call_sign: "KULT",
    broadcast_frequency: "94.5",
    audio_url: "https://streamer.radio.co/s39286623b/low",
    station_url: "https://kult.uni.edu/",
    college_name: "University of Northern Iowa",
    public_private: "Public",
    city: "Cedar Falls",
    state: "IA",
    station_image:
      "https://pbs.twimg.com/profile_images/658050317292974080/MTwiAo6H_400x400.jpg",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/8/86/Northern_Iowa_Panters_logo.svg/1200px-Northern_Iowa_Panters_logo.svg.png",
  },
  {
    id: 170,
    call_sign: "WWNO",
    broadcast_frequency: "89.9",
    audio_url: "https://newscast.talklouisiana.org/newscast/newscast.mp3",
    station_url: "https://www.wwno.org/",
    college_name: "University of New Orleans",
    public_private: "Public",
    city: "New Orleans",
    state: "LA",
    station_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxio6d3SQ4LHe9g2XMYU-dNqvFtSCOCAN8iQ&su",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/2/27/New_Orleans_Privateers_logo.svg/1200px-New_Orleans_Privateers_logo.svg.png",
  },
  {
    id: 171,
    call_sign: "WIIT",
    broadcast_frequency: "88.9",
    audio_url: "https://icecast.iit.edu/WIITstream",
    station_url: "https://radio.iit.edu/",
    college_name: "Illinois Institute of Technology",
    public_private: "Private",
    city: "Chicago",
    state: "IL",
    station_image:
      "https://radio.iit.edu/wp-content/uploads/2019/06/wiit_logo.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/9/96/Illinois_Institute_of_Technology_%28seal%29.svg",
  },
  {
    id: 172,
    call_sign: "WOBC",
    broadcast_frequency: "91.5",
    audio_url: "https://wobc.stream/main",
    station_url: "https://wobc-fm.org/",
    college_name: "Oberlin College",
    public_private: "Private",
    city: "Oberlin",
    state: "OH",
    station_image: "https://wobc-fm.org/Header.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/e/e0/Oberlin_logo_from_NCAA.svg",
  },
  {
    id: 173,
    call_sign: "WRDL",
    broadcast_frequency: "88.9",
    audio_url: "https://das-edge12-live365-dal02.cdnstream.com/a12400_2",
    station_url: "https://wrdlfm.com/",
    college_name: "Ashland University",
    public_private: "Private",
    city: "Ashland",
    state: "OH",
    station_image: "https://logo.clearbit.com/wrdlfm.com",
    college_image:
      "https://upload.wikimedia.org/wikipedia/en/thumb/9/92/Ashland_Eagles_logo.svg/1200px-Ashland_Eagles_logo.svg.png",
  },
  {
    id: 174,
    call_sign: "WDCR",
    broadcast_frequency: "1340",
    audio_url: "https://18703.live.streamtheworld.com/DC_RADIOAAC.aac",
    station_url: "https://webdcr.com/",
    college_name: "Dartmouth College",
    public_private: "Private",
    city: "Hanover",
    state: "NH",
    station_image:
      "https://upload.wikimedia.org/wikipedia/en/6/64/WDCR_logo.png",
    college_image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Dartmouth_College_Big_Green_logo.svg/1200px-Dartmouth_College_Big_Green_logo.svg.png",
  },
  // TEMPLATE
  // {
  //   id: 104,
  //   call_sign: "",
  //   broadcast_frequency: "",
  //   audio_url: "",
  //   station_url: "",
  //   college_name: "",
  //   public_private: "",
  //   city: "",
  //   state: "",
  //   station_image:
  //     "",
  //   college_image:
  //     "",
  //   palette: [],
  // },
  // Stability problem, won't load on mobile or desktop with cors-anywhere (fix later)
  // {
  //   id: 65,
  //   call_sign: "WBWC",
  //   broadcast_frequency: "88.3",
  //   audio_url: "http://wbwcradio.bw.edu:8000/",
  //   station_url: "https://www.wbwc.com/",
  //   college_name: "Baldwin Wallace University",
  //   public_private: "Private",
  //   city: "Berea",
  //   state: "OH",
  //   station_image:
  //     "https://cdn-profiles.tunein.com/s27671/images/logog.png?t=164311",
  //   college_image:
  //     "https://upload.wikimedia.org/wikipedia/en/thumb/4/48/Baldwin_Wallace_Yellow_Jackets_logo.svg/800px-Baldwin_Wallace_Yellow_Jackets_logo.svg.png",
  // },
  // NOTE: KJHK doesn't seem to have a livestream link; the ones I found redirect you to an elevator music stream: http://streaming.radionomy.com/JamendoLounge
  //  found IHeartRadio links on fmstream, but they don't load on desktop and they're shaky on mobile
  //  {
  //   id: 108,
  //   call_sign: "WVCR",
  //   broadcast_frequency: "88.3",
  //   audio_url: "https://stream.revma.ihrhls.com/zc6306/hls.m3u8",
  //   station_url: "https://www.wvcr.com/",
  //   college_name: "Siena College",
  //   public_private: "Private",
  //   city: "Loudonville",
  //   state: "NY",
  //   station_image: "https://cdn-radiotime-logos.tunein.com/s23485g.png",
  //   college_image:
  //     "https://upload.wikimedia.org/wikipedia/en/thumb/7/78/Siena_Saints_logo.svg/1200px-Siena_Saints_logo.svg.png",
  //   palette: ["#f7eacb", "#1e2015", "#809f72", "#7b7c7c"],
  // },
];

export default stations;
